<template>
    <apexchart :height="height" type="line" :options="chartOptions" :series="series" ref="gcLineChart"/>
</template>

<script>
export default {
    name: "LineChart",
    props: {
        chartOptions: {
            type: Object,
            default: () => {
                return {}
            }
        },
        series: {
            type: Array,
            default: () => {
                return [{
                    name: 'Global Concentration',
                    data: []
                }]
            }
        },
        height: {
            type: String,
            default: 'auto'
        }
    }
}
</script>

<style lang="scss">
</style>
