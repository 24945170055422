<template>
    <div>
        <b-row class="justify-content-start pl-3 m-0 mt-2" v-if="hasPollenBeenDetected">
            <b-col
                cols="12" md="6"
                v-for="(pollen, index) in getDetectedGlobalRisk"
                :key="index"
                class="add-cursor"
                @click="chosePollen(pollen)"
            >
                <b-row
                    class="justify-content-start my-1 mr-md-1 mr-0"
                    :class="`border-${riskColor(pollen.riskLevel)}`"
                >
                    <b-col cols="6" md="12" lg="6" align-self="center"
                           :style="{fontSize: fontSize(pollen.name)}"
                           :class="`clr-darkslategrey bg-white`"
                           class="text-center px-0">
                        {{ $t('pollen.type.' + pollen.name) | capitalize }}
                    </b-col>
                    <b-col cols="6" md="12" lg="6" class="text-center"
                           :class="`bg-${riskColor(pollen.riskLevel)}
                                    ${(pollen.riskLevel === 3 || pollen.riskLevel === 0) ? 'clr-white' : 'clr-darkslategrey'}`">
                        {{ $t('risk.' + levelString(pollen.riskLevel)) |capitalize }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row class="row justify-content-around m-0 mt-2 align-items-center" v-else>
            <b-col cols="4">
                <IconBase
                    icon-name="no-species-detected"
                    viewBox="0 0 132 120"
                    height="140"
                    width="140"
                >
                    <IconNoSpecies/>
                </IconBase>
            </b-col>

            <b-col cols="7">
                <p class="clr-lightslategrey empty-state-content"
                   v-if="filters.date && areOtherFiltersNull(['date'])">
                    {{
                        $t('common.title.popup.noSpeciesDate', {date: $options.filters.formattedDate(filters.date)}) | capitalize
                    }}
                </p>
                <p class="clr-lightslategrey empty-state-content"
                   v-else-if="filters.sensors.length === 1 && areOtherFiltersNull(['sensors'])">
                    {{ $t('common.title.popup.noSpeciesSensor') | capitalize }}
                </p>
                <p class="clr-lightslategrey empty-state-content"
                   v-else-if="filters.pollens.length > 0 && areOtherFiltersNull(['pollens'])">
                    {{ $t('common.title.popup.noSpeciesPollens') | capitalize }}
                </p>
                <p class="clr-lightslategrey empty-state-content"
                   v-else-if="filters.riskLevels.length > 0 && areOtherFiltersNull(['riskLevels'])">
                    {{ $t('common.title.popup.noSpeciesRiskLevels', {levels: formattedRiskLevels}) | capitalize }}
                </p>
                <p class="clr-lightslategrey empty-state-content" v-else-if="!areOtherFiltersNull(['showUserReports'])">
                    {{ $t('common.title.popup.noSpeciesFilters') | capitalize }}
                </p>
                <p class="clr-lightslategrey empty-state-content" v-else>
                    {{ $t('common.title.popup.noSpecies') | capitalize }}
                </p>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import Vuex from "vuex";
import SecureLS from "@/plugins/secureLS";
import pollenService from "@/services/pollen/pollenService";
import IconBase from "@/components/common/icons/IconBase";
import IconNoSpecies from "@/components/common/icons/IconNoSpecies";

export default {
    name: "RollingRisks",
    props: {
        maxRisk: Number || null,
        isDashboard: {
            type: Boolean,
            default: false,
        },
        serialNumber: String
    },
    data() {
        return {
            reportTypes: ['tree', 'weed', 'grass'],
        }
    },
    components: {
        IconNoSpecies,
        IconBase,
    },
    methods: {
        ...Vuex.mapActions('filters', {
            setFilters: 'setFilters',
        }),
        riskColor(value) {
            return pollenService.getColorByValue(value);
        },
        levelString(value) {
            return pollenService.getLevelString(value);
        },
        reports(type) {
            if (this.isDashboard) {
                return this.globalReportsByCategory(type)
            } else {
                return this.reportsBySensorAndCategory(this.serialNumber, type)
            }
        },
        fontSize(pollen) {
            const length = this.$i18n.t('pollen.type.' + pollen).length
            if (length < 10) return '16px';
            if (length >= 13) return '11px';
            if (length >= 10) return '12px';
        },
        areOtherFiltersNull(excludedField = []) {
            let res = true;
            Object.entries(this.filters).forEach(([field, value]) => {
                if (!excludedField.includes(field) && field !== 'fromMap') {
                    if (Array.isArray(value)) {
                        if (value.length > 0) {
                            res = false
                        }
                    } else if (typeof value === 'object' && value) {
                        res = false;
                    } else if (typeof value === 'boolean' && value) {
                        res = false;
                    } else if (value) {
                        res = false;
                    }
                }
            })
            return res;
        },
        chosePollen(pollen) {
            pollen.translatedName = this.$t(`pollen.type.${pollen.name}`).toLowerCase();
            this.setFilters({pollens: [pollen], fromMap: false})
        }
    },
    computed: {
        ...Vuex.mapGetters('report', {
            reportsBySensorAndCategory: 'reportsBySensorAndCategory',
            globalReportsByCategory: 'globalReportsByCategory',
            globalReports: 'globalReports'
        }),
        ...Vuex.mapGetters('rollingRisk', {
            getDetectedRiskByCategory: 'getDetectedRiskByCategory',
            getDetectedGlobalRisk: 'getDetectedGlobalRisk',
            hasPollenBeenDetected: 'hasPollenBeenDetected',
            hasPollenData: 'hasPollenData'
        }),
        ...Vuex.mapGetters('filters', {
            filters: 'filters',
        }),
        isAuthorized() {
            let currentUser = SecureLS.get('currentUser');
            return currentUser.roles.includes('ROLE_ADMIN') || currentUser.roles.includes('ROLE_SUPER_ADMIN')
        },
        formattedRiskLevels() {
            let text = '';
            this.filters.riskLevels.forEach((level, index) => {
                if (index !== 0) {
                    text += ' ' + this.$t('common.or') + ' '
                }
                text += this.$t(`risk.${level}`)
            })
            return text;
        },
    },
}
</script>

<style lang="scss">
.empty-state-content {
    margin-left: 10px;
}
</style>
