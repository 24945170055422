const defaultFilters = {
    accounts: [],
    pollens: [],
    showUserReports: null,
    riskLevels: [],
    sensors: [],
    visibility: null,
    date: null,
    fromMap: false
}

const state = {
    filters: defaultFilters,
    areDataLoading: false,
    request: {},
};


const mutations = {
    SET_FILTERS: (state, filters) => {
        state.filters = {...state.filters, ...filters};
    },
    RESET_FILTERS: (state) => {
        state.filters = {...defaultFilters};
        state.filteredSensors = [];
    },
    SET_ARE_DATA_LOADING: (state, isLoading) => {
        state.areDataLoading = isLoading;
    },
    RESET_FILTERED_SENSORS: (state) => {
        state.filteredSensors = [];
    },
    SET_REQUEST_RESPONSE: (state, response) => {
        state.request = {
            message: response.data.message,
            status: response.status,
            success: response.data.success
        }
    },
    SET_REQUEST_ERROR: (state, error) => {
        state.request = {
            message: error.response.data.message ?? error.response.statusText,
            status: error.response.data.error_code ?? error.response.status,
            success: error.response.data.success
        }
    },
};
const actions = {
    setFilters: (store, filters) => {
        store.commit('SET_FILTERS', filters)
    },
    setDataLoading: (store, isLoading) => {
        store.commit('SET_ARE_DATA_LOADING', isLoading)
    },
    resetFilters: (store) => {
        store.commit('RESET_FILTERS')
    },
};

const getters = {
    filters: state => {
        return state.filters
    },
    getLatestUpdatedByFilters() {
        if (state.filters.sensors.length > 0) {
            return new Date(
                Math.max(
                    ...state.filters.sensors.map(element => {
                        return new Date(element.lastUpdate);
                    }),
                ),
            )
        } else {
            return null;
        }
    },
    areDataLoading() {
        return state.areDataLoading;
    }
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
