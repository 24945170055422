<template>
    <div>
        <TheNavbar>
            <Burger></Burger>
        </TheNavbar>
        <TheSidebar>
            <div class="d-flex flex-column sidebar justify-content-between h-100">
                <div class="py-4 px-3">
                    <div class="empty-content"></div>
                    <p class="clr-lifyair add-cursor" @click="goTo('analytics')"
                       v-if="$route.name !== 'analytics'">
                        <b-icon-bar-chart-line-fill class="mr-2"/>
                        {{ $t('common.path.synthesis') | capitalize }}
                    </p>
                    <p class="clr-lifyair add-cursor" @click="goTo('/')" v-if="$route.name !== 'dashboard'">
                        <b-icon-map class="mr-2"/>
                        {{ $t('common.path.dashboard') | capitalize }}
                    </p>
                    <div class="divider"></div>
                    <DownloadButton v-if="apiKey" :btn-txt="'Télécharger la doc api'"
                                    type="API"></DownloadButton>
                    <DownloadButton v-if="apiKey" :btn-txt="'Télécharger la doc widget'"
                                    type="WIDGET"></DownloadButton>
                    <div v-if="apiKey" class="divider"></div>
                    <p class="clr-lifyair">{{ $t('common.language.this') | capitalize }}</p>
                    <b-dropdown id="language-choice" class="language-choice mb-3"
                                :text="$t(`common.language.${$i18n.locale}`)">
                        <b-dropdown-item
                            v-for="(lang, index) in languages"
                            :key="index"
                            @click="setLanguage(lang.value)">
                            {{ lang.text }}
                        </b-dropdown-item>
                    </b-dropdown>
                    <div class="divider"></div>
                    <p class="clr-lifyair">{{ $t('contact.contact') }}</p>
                    <p class="clr-lifyair" style="font-size: 14px">{{ $t('contact.contactInfo') }}</p>
                    <div class="divider"></div>
                </div>
                <b-nav vertical class="sidebar-item">
                    <b-link vertical class="sidebar-item text-center" @click="showCookie()">
                        {{ $t('common.cookieSettings') }}
                    </b-link>
                    <div class="d-flex align-items-center w-100 mb-1" style="justify-content: space-evenly">
                        <b-link vertical
                                class="sidebar-item text-center small"
                                href="https://live-pollen.com/politique-de-confidentialite-superviseur/"
                                target="_blank">{{ $t('common.cgu') }}
                        </b-link>
                        <span>|</span>
                        <b-link vertical
                                class="sidebar-item text-center small"
                                href="https://live-pollen.com/politique-cookies/"
                                target="_blank">{{ $t('common.cookiePolicy') }}
                        </b-link>
                    </div>
                    <li @click="logout()" class="nav-item mb-5 mb-md-0">
                        <a href="" class="btn-title secondary nav-link">
                            {{ $t('common.button.logout') | capitalize }}
                            <IconBase class="float-right" view-box="0 0 20 20" id="exit-icon">
                                <IconExit/>
                            </IconBase>
                        </a>
                    </li>
                </b-nav>
            </div>
        </TheSidebar>

        <vue-cookie-accept-decline
            :disable-decline="false"
            :show-postpone-button="false"
            element-id="cookiePanel"
            ref="cookiePanel"
            transition-name="slideFromBottom"
            type="floating"
            @status="cookieStatus"
            @removed-cookie="cookieRemovedCookie"
            @clicked-accept="acceptCookie(true)"
            @clicked-decline="acceptCookie(false)"
        >
            <template #message>
                <iconBase view-box="100 100 250 220" width="65px" height="65px">
                    <Picto></Picto>
                </iconBase>
                <span>{{ $t('common.cookieContent') }}</span>
                <a href="https://live-pollen.com/politique-cookies/" target="_blank">{{
                        $t('common.cookiePolicy')
                    }}</a>
            </template>
            <template #declineContent class="btn-live-pollen outlined mr-2">Refuser</template>
            <template #acceptContent class="btn-live-pollen">Accepter</template>
        </vue-cookie-accept-decline>
        <router-view style="max-height: calc(100% - 56px)">
            <slot></slot>
        </router-view>
    </div>
</template>

<script>
import TheNavbar from "./Menu/TheNavbar";
import TheSidebar from "./Menu/TheSidebar";
import Burger from "./Menu/Burger";
import SecureLS from "@/plugins/secureLS"
import {mutations} from '@/store/modules/navigation.js'
import Vuex from 'vuex'
import i18n from "@/i18n";
import IconBase from "@/components/common/icons/IconBase";
import IconExit from "@/components/common/icons/IconExit"
import store from "@/store";
import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';
import Picto from "@/components/common/icons/picto.vue";
import DownloadButton from "@/components/layout/Menu/DownloadButton.vue";

export default {
    data() {
        return {
            status: 'accept',
            apiKey : null
        }
    },
    components: {
        DownloadButton,
        Picto,
        Burger,
        TheSidebar,
        TheNavbar,
        IconBase,
        IconExit,
        VueCookieAcceptDecline
    },
    computed: {
        ...Vuex.mapGetters('account', {
            currentAccount: 'currentAccount'
        }),
        getRoutePath() {
            return this.$route.name
        },
        languages() {
            return [
                {value: 'fr', text: i18n.t('common.language.fr')},
                {value: 'en', text: i18n.t('common.language.en')},
            ]
        },
        isAdmin() {
            let currentUser = SecureLS.get('currentUser');
            if (currentUser) {
                return currentUser.roles.includes('ROLE_ADMIN') || currentUser.roles.includes('ROLE_SUPER_ADMIN')
            }
            return false;
        },
        isAccountManager() {
            let currentUser = SecureLS.get('currentUser');
            return currentUser.roles.includes('ROLE_ACCOUNT_MANAGER') || this.isAdmin;
        },
        isAuthorizedForTest() {
            let currentUser = SecureLS.get('currentUser');
            if (currentUser) {
                return currentUser.roles.includes('ROLE_ADMIN') ||
                    currentUser.roles.includes('ROLE_SUPER_ADMIN') ||
                    currentUser.roles.includes('ROLE_LIFYAIR_TEST') ||
                    currentUser.roles.includes('ROLE_LIFYAIR_COLLABORATOR');
            } else {
                return false
            }
        },
        areCookieAccepted() {
            return !(localStorage.getItem('acceptedCookies') || this.status === 'accept');
        }
    },
    methods: {
        closeSidebarPanel: mutations.toggleNav,
        goTo(path) {
            this.closeSidebarPanel()
            this.$router.push(path)
        },
        ...Vuex.mapActions('login', {
            logout: 'logout'
        }),
        setLanguage(language) {
            this.closeSidebarPanel()
            store.dispatch('setLanguage', language)
            this.$i18n.locale = language
        },
        cookieStatus() {
            if (localStorage.getItem('acceptedCgu')) {
                return localStorage.getItem('acceptedCgu');
            } else {
                return this.status
            }
        },
        cookieRemovedCookie() {
            this.status = null;
            this.$refs.cookiePanel.init();
        },
        showCookie() {
            this.$gtag.optOut();
            this.$refs.cookiePanel.removeCookie();
        },
        acceptCookie(accept = false) {
            if (accept) {
                if (process.env.VUE_APP_PROJECT_ENV === 'prod') {
                    localStorage.setItem('acceptedCookies', 'true');
                    this.$gtag.optIn();
                    this.$gtag.event('cookie', {
                        'event_category': 'cookie',
                        'event_label': 'cookie',
                        'value': true
                    });
                }
            } else {
                this.$gtag.optOut();
                localStorage.setItem('acceptedCookies', 'false');
                document.cookie = "_ga_6RKCDLZRFM" + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                document.cookie = "_ga" + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            }
        }
    },
    mounted() {
        let currentUser = SecureLS.get('currentUser');
        this.apiKey = currentUser.apiKey;
        SecureLS.set('currentPath', this.getRoutePath);
        if (localStorage.getItem('acceptedCookies')) {
            this.status = 'accept';
        }
        this.$refs.cookiePanel.init();

    }
}

</script>

<style lang="scss">
.divider {
    @include divider($orange-lifyair, 95%, 1px);
    margin-bottom: 12px;
}

.empty-content {
    height: 80px;
}

.sidebar {
    .btn {
        border-radius: 0;
        background-color: $white;
        border-width: 1px;
        color: $dark-orange-lifyair;
        border-color: $dark-orange-lifyair;
        font-size: 16px;
        text-transform: unset;
        font-family: 'Myriad Pro Regular', sans-serif;
    }

    #language-choice__BV_toggle_ {
        background-color: $white;
        color: $dark-orange-lifyair;
        border-color: $dark-orange-lifyair;
    }

    .sidebar-item {
        & > li {
            padding: 5px;
            color: $dark-orange-lifyair;

            & > a {
                color: $dark-orange-lifyair;
            }
        }

        & > li:hover {
            background-color: $dark-orange-lifyair;

            & > a {
                color: $white;
            }
        }
    }
}

#exit-icon {
    svg {
        fill: $dark-orange-lifyair;

        &:hover {
            fill: $white;
        }
    }
}

.select-css {
    color: $dark-orange-lifyair;
    border-radius: unset;
    max-width: 100%;
    border: 1px solid $dark-orange-lifyair;
    background: $white;
    font-size: 16px;
}

.language-choice {
    .dropdown-toggle {
        width: 250px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid $dark-orange-lifyair;
        background-color: $white;
        color: $dark-orange-lifyair;
    }

    .dropdown-toggle:focus {
        box-shadow: none !important;
    }

    .dropdown-menu {
        width: 250px;
        border: 1px solid $dark-orange-lifyair;
        border-radius: 0;
        padding: 0rem 0;
        color: $dark-orange-lifyair;
        transform: translate3d(0px, 38px, 0px) !important;
    }

    .dropdown-item:focus, .dropdown-item:hover {
        color: $white;
        background-color: $dark-orange-lifyair;
        padding: 12px;
    }

    .dropdown-item {
        display: block;
        width: 100%;
        padding: 12px;
        font-size: 16px;
        color: $dark-orange-lifyair;

    }
}

.select-css:focus {
    color: #222;
    outline: none;

}
</style>
