import SecureLS from "@/plugins/secureLS";

const user = SecureLS.get('currentUser');
const state = user ? {user:user} : {user};

const mutations = {
};
const actions = {
};

const getters = {
	getUser: state => {
		return state.user
	},
};

export default {
	namespaced: true,
	state: state,
	mutations: mutations,
	actions: actions,
	getters: getters
}
