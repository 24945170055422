import api from "@/services/api";

const state = {
    reportTypes: [],
    request: {},
};


const mutations = {
    SET_REPORT_TYPES: (state, reportTypes) => {
        state.reportTypes = reportTypes;
    },
    SET_REQUEST_RESPONSE: (state, response) => {
        state.request = {
            message: response.data.message,
            status: response.status,
            success: response.data.success
        }
    },
    SET_REQUEST_ERROR: (state, error) => {
        state.request = {
            message: error.response.data.message ?? error.response.statusText,
            status: error.response.data.error_code ?? error.response.status,
            success: error.response.data.success
        }
    },
};
const actions = {
    setReportTypes: async (store) => {
        await api.reportType.getReportTypes()
            .then(response => {
                store.commit('SET_REPORT_TYPES', response.data.data);
                store.commit('SET_REQUEST_RESPONSE', response);
            })
            .catch( err => {
                store.commit('SET_REQUEST_ERROR', err);
            });
    },
};

const getters = {
    reportTypes: state => {
        return state.reportTypes
    },
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
