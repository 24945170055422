import axios from "../../plugins/axios";
import authHeader from "./authHeader";
import querystring from "querystring";

export default {
	getAccounts,
	getCurrentAccount,
}

function getCurrentAccount() {
	return axios.get('/accounts/current',authHeader.classicalHeader())
}
function getAccounts(data) {
	return axios.get('/map/accounts?'+querystring.stringify(data),authHeader.classicalHeader())
}
