<template>
	<div class="sidebar">
		<div class="sidebar-backdrop" @click="closeSidebarPanel" v-if="isPanelOpen"></div>
		<transition name="slide">
			<div v-if="isPanelOpen" class="sidebar-panel">
				<slot></slot>
			</div>
		</transition>
	</div>
</template>
<script>
	import { navigation, mutations } from '@/store/modules/navigation.js'
	export default {
		methods: {
			closeSidebarPanel: mutations.toggleNav
		},
		computed: {
			isPanelOpen() {
				return navigation.isNavOpen
			}
		}
	}
</script>
<style lang="scss">
	.slide-enter-active,
	.slide-leave-active
	{
		transition: transform 0.2s ease;
	}

	.slide-enter,
	.slide-leave-to {
		transform: translateX(-100%);
		transition: all 150ms ease-in 0s
	}

	.sidebar-backdrop {
		background-color: rgba(0,0,0,.5);
		width: 100vw;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		cursor: pointer;
		z-index: 1000;
	}

	.sidebar-panel {
		color: $dark-orange-lifyair;
		overflow-y: auto;
		position: fixed;
		left: 0;
		top: 0;
		height: 100vh;
		width: 300px;
		box-shadow: 1px 1px 12px grey;
		z-index: 1001;
        background-color: $white;
	}
</style>
