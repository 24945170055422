import axios from "../../plugins/axios";
const querystring = require('querystring');
import authHeader from "./authHeader";

export default {
	getReports,
	getWeatherReports
}

function getReports(data) {
	return axios.get('/map/reports?'+querystring.stringify(data),authHeader.classicalHeader())
}

function getWeatherReports(data) {
	return axios.get('/datareports?type=weather&'+querystring.stringify(data),authHeader.classicalHeader())
}

