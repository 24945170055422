<template>
    <div class="bg-white">
        <div class="row justify-content-center no-gutters dashboard-card-container bg-white pt-3 align-items-start">

            <!-- SERIAL NUMBER -->
            <b-col cols="12" class="pl-3 pb-1" v-if="isAuthorized && filters.sensors.length === 1">
                <b-badge class="sensor-serial-number" target="_blank"
                         :href="`${adminUrl}sensors/${filters.sensors[0].serialNumber}/detail`">
                    {{ '#' + filters.sensors[0].serialNumber }}
                </b-badge>
            </b-col>

            <!-- ACCOUNT NAME TITLE -->
            <b-col cols="10" md="12"
                   class="pl-3 pb-0 container-title clr-darkslategrey"
                   align-self="center"
                   v-if="filters.accounts.length === 1 && areOtherFiltersNull(['accounts'])"
            >
                {{ $t('common.title.synthesisSensor') | capitalize }} {{ filters.accounts[0].name }}
            </b-col>

            <!-- CHOSEN DATE TITLE -->
            <b-col cols="10" md="12"
                   class="pl-3 pb-0 container-title clr-darkslategrey"
                   align-self="center"
                   v-else-if="filters.date && areOtherFiltersNull(['date','showUserReports'])"
            >
                {{
                    $t('common.title.synthesisDate', {date: $options.filters.formattedDate(filters.date)}) | capitalize
                }}
            </b-col>

            <!-- CHOSEN SENSOR TITLE -->
            <b-col cols="10" md="12"
                   class="pl-3 pb-0 container-title clr-darkslategrey"
                   align-self="center"
                   v-else-if="filters.sensors.length === 1 && areOtherFiltersNull(['sensors'])"
            >
                {{ $t('common.title.synthesisSensor') | capitalize }} {{ sensorName }}
            </b-col>

            <!-- MULTIPLE FILTERS TITLE -->
            <b-col cols="10" md="12"
                   class="pl-3 pb-0 container-title clr-darkslategrey"
                   align-self="center"
                   v-else-if="!areOtherFiltersNull(['showUserReports'])"
            >
                {{ $t('common.title.synthesisByFilters') | capitalize }}
            </b-col>

            <!-- STANDARD TITLE -->
            <b-col cols="10" md="12"
                   class="pl-3 pb-0 container-title clr-darkslategrey"
                   align-self="center"
                   v-else>
                {{ $t('common.title.synthesis') | capitalize }}
            </b-col>

            <!-- CLOSE CONTROL -->
            <b-col cols="1" class="d-flex d-md-none">
                <b-icon icon="x" class="close-control" variant="primary" @click="hideControl()"/>
            </b-col>
            <b-col cols="1" class="d-flex d-md-none">
            </b-col>

            <!-- SEARCH NOT FOUND -->
            <b-row cols="12" class="dashboard-card justify-content-center"
                   v-if="searchNotFound"
                   align-v="center">
                <b-col cols="12" class="text-center">
                    <IconBase viewBox="0 0 451 434" width="30%" height="100%">
                        <IconSearchNotFound/>
                    </IconBase>
                </b-col>
                <b-col cols="12" class="mt-3 pollen-square-detail text-center">
                    <p class="clr-dimgrey m-0">{{ $t('emptyState.searchNotFound') }}</p>
                </b-col>
            </b-row>

            <!-- ALL SENSORS IN DEPLOYMENT -->
            <b-row cols="12" class="dashboard-card justify-content-center"
                   v-else-if="areSensorsInDeployment && !sensorIsEmpty"
                   align-v="center">
                <b-col cols="6" md="4" class="m-0">
                    <IconBase viewBox="0 0 121 120" width="100%" height="100%">
                        <IconDeploying/>
                    </IconBase>
                </b-col>
                <b-col cols="12" md="8" class="m-0 pl-0 pollen-square-detail">
                    <p class="clr-dimgrey m-0">{{ $t('emptyState.inDeployment') }}</p>
                    <p class="clr-lightslategrey m-0">{{ $t('emptyState.inDeploymentDetail') }}</p>
                </b-col>
            </b-row>

            <!-- DISCONNECTED SENSORS -->
            <b-row class="dashboard-card mb-2"
                   v-else-if="(isSensorOnMoreThanOneDay || isSensorOffForMoreThanTwoWeeks) && !sensorIsEmpty">
                <p class="pl-3 container-title">
                    {{ $tc(`emptyState.disconnectedGlobal`, filters.sensors.length === 1 ? 1 : 2) | capitalize }}
                </p>
                <b-row class="pl-3 m-0 justify-content-center">
                    <b-col cols="6" md="4" class="m-0">
                        <IconBase viewBox="0 0 360 384" width="100%" height="100%">
                            <IconMaintenance/>
                        </IconBase>
                    </b-col>
                    <b-col cols="12" md="8"
                           class="m-0 pl-0 pollen-square-detail"
                           :class="isSensorOffForMoreThanTwoWeeks ? 'mt-md-4' : ''">
                        <p class="clr-lightslategrey m-0" v-if="filters.sensors.length === 1">
                            {{ $tc('emptyState.disconnectedDetailSensor', isSensorOffForMoreThanTwoWeeks ? 2 : 1) }}
                        </p>
                        <p class="clr-lightslategrey m-0" v-else>
                            {{ $tc('emptyState.disconnectedDetailGlobal', isSensorOffForMoreThanTwoWeeks ? 2 : 1) }}
                        </p>
                    </b-col>
                </b-row>
            </b-row>

            <!-- NO DATA FOR SENSORS -->
            <b-row class="dashboard-card mb-md-3"
                   v-else-if="((!hasPollenHistoryData && filters.date) || !hasPollenData) && !sensorIsEmpty">
                <p class="pl-3 container-title">
                    {{ $tc(`emptyState.disconnectedGlobal`, filters.sensors.length === 1 ? 1 : 2) | capitalize }}
                </p>
                <b-row class="pl-3 m-0 justify-content-center">
                    <b-col cols="6" md="4" class="m-0">
                        <IconBase viewBox="0 0 360 384" width="100%" height="100%">
                            <IconMaintenance/>
                        </IconBase>
                    </b-col>
                    <b-col cols="12" md="8" class="m-0 mt-md-4 pl-0 pollen-square-detail">
                        <p class="clr-lightslategrey m-0" v-if="filters.date && filters.sensors.length === 1">
                            {{
                                $tc(
                                    'emptyState.disconnectedDetailDate',
                                    3,
                                    {date: $options.filters.formattedDate(filters.date), name: sensorName}
                                ) | capitalize
                            }}
                        </p>
                        <p class="clr-lightslategrey m-0" v-else-if="filters.date">
                            {{
                                $tc('emptyState.disconnectedDetailDate', hasPollenHistoryData ? 2 : 1, {date: $options.filters.formattedDate(filters.date)}) | capitalize
                            }}
                        </p>
                        <p class="clr-lightslategrey m-0" v-else>
                            {{ $tc('emptyState.disconnectedDetailGlobal', isSensorOffForMoreThanTwoWeeks ? 2 : 1) }}
                        </p>
                    </b-col>
                </b-row>
            </b-row>

            <!-- GLOBAL CONCENTRATION // POLLEN PRESENCE -->
            <b-row class="col-12 dashboard-card"
                   v-if="showGlobalConcentration">
                <p class="pl-3 container-title col-12 m-0">
                    {{ $t(`common.title.popup.pollenPresence`) | capitalize }}
                </p>
                <p class="pl-3 subtitle col-12 m-0"
                   v-if="filters.sensors.length === 1 && areOtherFiltersNull(['sensors', 'date'])">
                    {{
                        $tc(`common.title.popup.pollenPresenceDetailSensor`, filters.date ? 2 : 1, {
                            name: sensorName,
                            date: $options.filters.formattedDate(filters.date)
                        }) | capitalize
                    }}
                </p>
                <p class="pl-3 subtitle col-12 m-0" v-else-if="!areOtherFiltersNull(['showUserReports'])">
                    {{
                        $tc(`common.title.popup.pollenPresenceDetailFilters`, filters.date ? 2 : 1, {date: $options.filters.formattedDate(filters.date)}) | capitalize
                    }}
                </p>
                <p class="pl-3 subtitle col-12 m-0" v-else>
                    {{
                        $tc(`common.title.popup.pollenPresenceDetail`, filters.date ? 2 : 1, {date: $options.filters.formattedDate(filters.date)}) | capitalize
                    }}
                </p>
                <DailyGlobalConcentration v-if="isAuthorized" class="col-12"/>
<!--                <PollenPresence v-else-if="!isAuthorizedForTest" class="col-12" :detected-pollen="hasPollenBeenDetected"/>-->
            </b-row>

            <!-- MEASURED POLLENS -->
            <b-row class="col-12 dashboard-card mb-3" v-if="showRollingRisks">
                <p class="pl-3 col-12 container-title m-0">
                    {{ $t(`common.title.popup.pollenRisk`) | capitalize }}
                </p>

                <!-- WITH CHOSEN SENSOR -->
                <p class="pl-3 col-12 subtitle m-0"
                   v-if="filters.sensors.length === 1 && areOtherFiltersNull(['sensors', 'date'])">
                    {{
                        $tc(`common.title.popup.pollenDetailSensor`, filters.date ? 2 : 1, {date: $options.filters.formattedDate(filters.date)}) | capitalize
                    }}
                </p>

                <!-- WITH CHOSEN DATE -->
                <p class="pl-3 col-12 subtitle m-0"
                   v-else-if="filters.date && areOtherFiltersNull(['date','showUserReports'])">
                    {{
                        $tc(`common.title.popup.pollenDetailFilters`, 2, {date: $options.filters.formattedDate(filters.date)}) | capitalize
                    }}
                </p>

                <!-- WITH MULTIPLE SENSORS -->
                <p class="pl-3 col-12 subtitle m-0" v-else-if="!areOtherFiltersNull(['showUserReports'])">
                    {{
                        $tc(`common.title.popup.pollenDetailFilters`, 1) | capitalize
                    }}
                </p>

                <!-- WITHOUT FILTERS -->
                <p class="pl-3 col-12 subtitle m-0" v-else>
                    {{ $t(`common.title.popup.pollenDetail`) | capitalize }}
                </p>
                <RollingRisks class="col-12 p-0" :is-dashboard="true"/>
            </b-row>

            <!-- RISK EVOLUTION -->
            <div class="col-12 row dashboard-card" v-if="showRollingRisksEvolution">
                <p class="pl-3 col-12 container-title m-0">
                    {{ $t(`common.title.popup.analytics`) | capitalize }}
                </p>

                <!-- WITH CHOSEN DATE -->
                <p class="pl-3 col-12 subtitle m-0"
                   v-if="filters.date && areOtherFiltersNull(['date','showUserReports'])">
                    {{
                        $tc(
                            `common.title.popup.analyticsDetailFilters`,
                            2,
                            {date: $options.filters.formattedDate(filters.date)}
                        ) | capitalize
                    }}
                </p>

                <!-- WITH MULTIPLE FILTERS -->
                <p class="pl-3 col-12 subtitle m-0" v-else-if="!areOtherFiltersNull(['showUserReports'])">
                    {{ $tc(`common.title.popup.analyticsDetailFilters`, 1) | capitalize }}
                </p>

                <!-- WITHOUT FILTERS -->
                <p class="pl-3 col-12 subtitle m-0" v-else>
                    {{ $t(`common.title.popup.analyticsDetail`) | capitalize }}
                </p>
                <RollingRiskEvolution class="col-12 " :is-dashboard="true"/>
            </div>

            <!-- USER ANALYTICS -->
            <b-row class="col-12 dashboard-card" v-if="showUserAnalytics">
                <p class="pl-3 col-12 container-title m-0">
                    {{ $t(`common.title.popup.userAnalyticsOnYourArea`) | capitalize }}
                </p>
                <p class="pl-3 col-12 date-subtitle mb-3"
                   v-if="filters.date">
                    {{ $t('date.LL', {date: $options.filters.formattedDate(filters.date)}) }}
                </p>
                <p class="pl-3 col-12 date-subtitle mb-3" v-else>
                    {{ $t('date.lastDays', {number: 30}) }}
                </p>

                <!-- TOO EARLY FOR USER ANALYTICS -->
                <b-row class="col-12 pr-0 mb-5" v-if="!isDateValidForUserAnalytics">
                    <b-col cols="6" md="4" class="m-0">
                        <IconBase viewBox="0 0 78 67" width="100%" height="100%">
                            <IconData/>
                        </IconBase>
                    </b-col>
                    <b-col cols="12" md="8" class="m-0 pl-0 pollen-square-detail">
                        <p class="clr-lightslategrey m-0">
                            {{ $t('emptyState.noUserAnalyticsYet') }}
                        </p>
                    </b-col>
                </b-row>

                <b-row class="col-12 pr-0 mb-0" v-else>
                    <!-- SENSOR CONNEXIONS ANALYTICS -->
                    <b-col cols="12" md="auto">
                        <p class="col-12 subtitle m-0 p-0">
                            {{
                                $tc(`common.title.popup.sensorsConnexion`, filters.sensors.length > 0 ? 1 : 0) | capitalize
                            }}
                        </p>
                        <p class="m-0 user-reports-count bold">{{ sensorsConnexionCount | abbreviateNumber }}</p>
                    </b-col>

                    <!-- NOTIFICATION SETTINGS ANALYTICS -->
                    <b-col cols="12" md="auto" v-if="isAuthorized">
                        <p class="col-12 subtitle m-0 p-0">
                            {{
                                $tc(`common.title.popup.notificationSettingsDetail`, 1) | capitalize
                            }}
                        </p>
                        <p class="m-0 user-reports-count bold">{{ notificationSettingsCount | abbreviateNumber }}</p>
                    </b-col>

                    <!-- SENSOR CONNEXIONS AREA ANALYTICS -->
                    <b-col
                            cols="12"
                            md="auto"
                            v-if="((isAuthorized && filters.accounts.length === 1) || currentAccount.planLevel !== 'pro') && actualArea">
                        <p class="col-12 subtitle m-0 p-0">
                            {{
                                $tc(`common.title.popup.sensorsConnexionAreaCount`, filters.sensors.length > 0 ? 1 : 0) | capitalize
                            }} : {{ actualArea }}
                        </p>
                        <p class="m-0 user-reports-count bold">{{ sensorsConnexionAreaCount | abbreviateNumber }}</p>
                    </b-col>
                </b-row>

                <!-- COMMUNICATION KIT HELPER -->
                <b-row class="com-kit-helper py-2 mb-3 align-items-center"
                       v-if="isDateValidForUserAnalytics && (isAuthorized || currentAccount.planLevel !== 'pro')">
                    <b-col cols="3" md="3" class="m-0">
                        <IconBase viewBox="0 0 90 88" width="90px" height="88px">
                            <IconSocialNetworksTree/>
                        </IconBase>
                    </b-col>
                    <b-col cols="9" md="9">
                        <i18n class="subtitle m-0 ml-2"
                              path="externalResources.communicationKit.text"
                              tag="p">
                            <template v-slot:url>
                                <a :href="communicationKitUrl"
                                   target="_blank"
                                   class="link">
                                    {{ $t('externalResources.communicationKit.linkText') }}
                                </a>
                            </template>
                        </i18n>
                    </b-col>
                </b-row>
            </b-row>

            <!-- USER REPORTS -->
            <div class="col-12 row dashboard-card" v-if="filters.showUserReports">
                <p class="pl-3 col-12 container-title m-0">
                    {{ $t(`common.title.popup.userReports`) | capitalize }}
                </p>
                <p class="pl-3 col-12 subtitle m-0" v-if="filters.date">
                    {{
                        $t(`common.title.popup.userReportsDetailDate`,
                            {date: $options.filters.formattedDate(filters.date)}) | capitalize
                    }}
                </p>
                <p class="pl-3 col-12 subtitle m-0" v-else>
                    {{ $t(`common.title.popup.userReportsDetail`) | capitalize }}
                </p>
                <p class="pl-3 m-0 user-reports-count bold">{{ userReportsCount }}</p>
            </div>
            <div class="col-12 row dashboard-card" v-if="filters.showUserReports">
                <p class="pl-3 col-12 container-title m-0">
                    {{ $t(`common.title.popup.useReportsEvolution`) | capitalize }}
                </p>
                <p class="pl-3 col-12 subtitle m-0" v-if="filters.date">
                    {{
                        $t(`common.title.popup.useReportsEvolutionDetailDate`,
                            {date: $options.filters.formattedDate(filters.date)}) | capitalize
                    }}
                </p>
                <p class="pl-3 col-12 subtitle m-0" v-else>
                    {{ $t(`common.title.popup.useReportsEvolutionDetail`) | capitalize }}
                </p>
                <UserReportsEvolution class="col-12"/>
            </div>

            <!-- LEGEND FOR MOBILE -->
            <div class="col-12 dashboard-card d-flex d-md-none row justify-content-around">
                <p class="pl-3 container-title col-12">{{ $t('common.button.legend') | capitalize }}</p>
                <div v-for="(level, index) in riskList" :key="index"
                     class="text-center align-self-center">
                    <b-icon icon="circle-fill" class="dashboard-legend-icon"
                            :class="`clr-${level.color}`"/>
                    <p class="subtitle m-0" :class="`clr-${level.color}`"
                       v-if="level.value > 1">{{ $t('risk.this') | capitalize }}</p>
                    <p class="subtitle m-0" :class="`clr-${level.color}`">{{ level.name }}</p>
                    <p class="subtitle m-0" :class="`clr-${level.color}`"
                       v-if="level.value === 1">{{ $t('risk.this') | capitalize }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vuex from 'vuex'
// Components
// import PollenPresence from "@/components/dashboard/pollenPresence/PollenPresence";
import RollingRisks from "@/components/dashboard/rollingRisk/RollingRisks";
import RollingRiskEvolution from "@/components/dashboard/rollingRisk/RollingRiskEvolution";
import DailyGlobalConcentration from "@/components/dashboard/globalConcentration/DailyGlobalConcentration";
import UserReportsEvolution from "@/components/dashboard/userReport/UserReportsEvolution";
// Services
import risk from "@/services/risk/risk";
import moment from "moment";
import SecureLS from "@/plugins/secureLS";
// Icons
import IconBase from "@/components/common/icons/IconBase";
import IconSearchNotFound from "@/components/common/icons/IconSearchNotFound";
import IconMaintenance from "@/components/common/icons/IconMaintenance";
import IconDeploying from "@/components/common/icons/IconDeploying";
import IconData from "@/components/common/icons/IconData.vue";
import IconSocialNetworksTree from "@/components/common/icons/IconSocialNetworksTree.vue";

export default {
    name: "DashboardOverlayCard",
    components: {
        IconSocialNetworksTree,
        IconData,
        RollingRiskEvolution,
        RollingRisks,
        // PollenPresence,
        UserReportsEvolution,
        DailyGlobalConcentration,
        IconSearchNotFound,
        IconDeploying,
        IconMaintenance,
        IconBase,
    },
    data() {
        return {
            adminUrl: process.env.VUE_APP_ADMIN_URL,
            communicationKitUrl: process.env.VUE_APP_COMMUNICATION_KIT_URL,
        }
    },
    computed: {
        ...Vuex.mapGetters('sensor', {
            getLatestSensorUpdated: 'getLatestSensorUpdated',
            areSensorsInDeployment: 'areSensorsInDeployment',
            sensors: 'sensors'
        }),
        ...Vuex.mapGetters('filters', {
            filters: 'filters',
            getLatestUpdatedByFilters: 'getLatestUpdatedByFilters'
        }),
        ...Vuex.mapGetters('userReport', {
            userReportsCount: 'userReportsCount',
        }),
        ...Vuex.mapGetters('notificationSettings', {
            notificationSettingsCount: 'notificationSettingsCount'
        }),
        ...Vuex.mapGetters('sensorAnalytic', {
            sensorsConnexionCount: 'sensorsConnexionCount',
            sensorsConnexionAreaCount: 'sensorsConnexionAreaCount',
            actualArea: 'actualArea',
        }),
        ...Vuex.mapGetters('rollingRisk', {
            hasPollenBeenDetected: 'hasPollenBeenDetected',
            hasPollenData: 'hasPollenData',
            hasPollenHistoryData: 'hasPollenHistoryData',
        }),
        ...Vuex.mapGetters('account', {
            currentAccount: 'currentAccount'
        }),
        riskList() {
            return risk.getRiskList().reverse()
        },
        sensorIsEmpty() {
            return this.sensors.length === 0;
        },
        isSensorOn() {
            const now = moment();
            if (this.filters.sensors.length > 0) {
                return moment(this.getLatestUpdatedByFilters) >= now.subtract(24, 'hours');
            } else {
                return moment(this.getLatestSensorUpdated) >= now.subtract(24, 'hours');
            }
        },
        isSensorOnMoreThanOneDay() {
            const now = moment();
            if (this.filters.sensors.length > 0) {
                return moment(this.getLatestUpdatedByFilters) < now.subtract(24, 'hours')
                    && moment(this.getLatestUpdatedByFilters) > now.subtract(14, 'days')
            } else {
                return moment(this.getLatestSensorUpdated) < now.subtract(24, 'hours')
                    && moment(this.getLatestSensorUpdated) > now.subtract(14, 'days')
            }
        },
        lastUpdateTime() {
            if (this.filters.sensors.length > 0) {
                return moment(this.getLatestUpdatedByFilters).format('LT');
            } else {
                return moment(this.getLatestSensorUpdated).format('LT');
            }
        },
        isSensorOffForMoreThanTwoWeeks() {
            const now = moment();
            if (this.filters.sensors.length > 0) {
                return moment(this.getLatestUpdatedByFilters) < now.subtract(14, 'days');
            } else {
                return moment(this.getLatestSensorUpdated) < now.subtract(14, 'days');
            }
        },
        sensorName() {
            let sensorName = ''
            if (this.filters.sensors.length > 0) {
                if (this.filters.sensors[0].sensorName) {
                    sensorName = this.filters.sensors[0].sensorName;
                } else if (this.filters.sensors[0].locality) {
                    sensorName = this.filters.sensors[0].locality
                }
            }
            return sensorName;
        },
        isAuthorized() {
            let currentUser = SecureLS.get('currentUser');
            return currentUser.roles.includes('ROLE_ADMIN') ||
                currentUser.roles.includes('ROLE_SUPER_ADMIN') ||
                currentUser.roles.includes('ROLE_LIFYAIR_COLLABORATOR');
        },
        isAuthorizedForTest() {
            let currentUser = SecureLS.get('currentUser');
            return currentUser.roles.includes('ROLE_ADMIN') ||
                currentUser.roles.includes('ROLE_SUPER_ADMIN') ||
                currentUser.roles.includes('ROLE_LIFYAIR_TEST') ||
                currentUser.roles.includes('ROLE_LIFYAIR_COLLABORATOR');
        },
        searchNotFound() {
            return this.sensors.length === 0;
        },
        showUserAnalytics() {
            if ((this.isSensorOnMoreThanOneDay || this.isSensorOffForMoreThanTwoWeeks) && this.hasPollenHistoryData) {
                return true;
            } else if (!this.hasPollenHistoryData && this.filters.date) {
                return false;
            } else if (this.filters.pollens.length === 0 &&
                this.filters.riskLevels.length === 0 &&
                !this.isSensorOffForMoreThanTwoWeeks &&
                this.isSensorOn) {
                return true;
            }
            return false;
        },
        isDateValidForUserAnalytics() {
            if (this.filters.date) {
                return moment(this.filters.date) > moment('2023-01-01')
            } else {
                return true;
            }
        },
        showRollingRisksEvolution() {
            if (this.isAuthorized && this.filters.accounts.length === 0) {
                return false;
            } else if (!this.hasPollenHistoryData) {
                return false;
            } else if (!this.searchNotFound &&
                !this.areSensorsInDeployment &&
                !this.isSensorOffForMoreThanTwoWeeks &&
                this.filters.riskLevels.length === 0) {
                return true;
            }
            return false;
        },
        showGlobalConcentration() {
            if (this.isAuthorized && this.filters.accounts.length === 0) {
                return false;
            }
            return this.isAuthorized && (!this.searchNotFound &&
                !this.areSensorsInDeployment &&
                !this.isSensorOnMoreThanOneDay &&
                !this.isSensorOffForMoreThanTwoWeeks &&
                this.filters.riskLevels.length === 0 &&
                this.filters.pollens.length === 0)
        },
        showRollingRisks() {
            if (this.isSensorOnMoreThanOneDay || this.isSensorOffForMoreThanTwoWeeks) {
                return false;
            } else if (this.hasPollenData) {
                return !this.searchNotFound &&
                    !this.areSensorsInDeployment &&
                    !this.isSensorOnMoreThanOneDay &&
                    !this.isSensorOffForMoreThanTwoWeeks
            }
            return false;
        }
    },
    methods: {
        hideControl() {
            this.$emit('triggerControl', [])
        },
        areOtherFiltersNull(excludedField = []) {
            let res = true;
            Object.entries(this.filters).forEach(([field, value]) => {
                if (!excludedField.includes(field) && field !== 'fromMap') {
                    if (Array.isArray(value)) {
                        if (value.length > 0) {
                            res = false
                        }
                    } else if (typeof value === 'object' && value) {
                        res = false;
                    } else if (typeof value === 'boolean' && value) {
                        res = false;
                    } else if (value) {
                        res = false;
                    }
                }
            })
            return res;
        }
    },
}
</script>

<style lang="scss" scoped>
.close-control {
  width: 50px;
  height: 50px;
  cursor: pointer;
  margin-left: auto;
}

.dashboard-card-container {
  background-color: $white;
  max-height: 92vh !important;
}

.divider {
  @include divider($light-grey, 95%, 2px);
}

.user-reports-count {
    font-size: 40px;
}

.dashboard-card {
  margin: 5px 0;
  background-color: $white;

  .pollen-square-detail {
    padding: 1px 5px;
    margin: 1px 5px;
  }
}

@media only screen and (max-width: 600px) {
  .b-skeleton {
    width: 95vw;
  }
}

.sensor-serial-number {
  background-color: $snow;
  box-shadow: 1px 0 4px 0 grey;
  padding: 5px 10px;
  font-size: 18px;
  border-radius: unset;
}

.date-subtitle {
  margin: -7px 0 0 0;
  font-weight: bold;
  color: $orange-lifyair;
}

.com-kit-helper {
  border: 1px solid $orange-lifyair;
  margin: 0 20px 0 20px;
}
</style>
