import api from '@/services/api/index.js'

const state = {
    sensorsConnexionCount: 0,
    sensorsConnexionAreaCount: 0,
    isSensorAnalyticLoading: false,
    actualArea: null,
    request: {
        data: {},
        error: {}
    }
};

const mutations = {
    SET_SENSORS_CONNEXION_COUNT: (state, sensorsConnexionCount) => {
        state.sensorsConnexionCount = sensorsConnexionCount;
    },
    SET_SENSORS_CONNEXION_AREA_COUNT: (state, sensorsConnexionAreaCount) => {
        state.sensorsConnexionAreaCount = sensorsConnexionAreaCount;
    },
    SET_ACTUAL_AREA: (state, actualArea) => {
        state.actualArea = actualArea;
    },
    SET_SENSOR_ANALYTIC_LOADING: (state) => {
        state.isSensorAnalyticLoading = !state.isSensorAnalyticLoading
    },
};

const actions = {
    setSensorsConnexionCount: async (store, params) => {
        store.commit('SET_SENSOR_ANALYTIC_LOADING');
        await api.sensorAnalytic.getSensorsConnexionCount(params)
            .then(response => {
                store.commit('SET_SENSORS_CONNEXION_COUNT', response.data.data.sensorsConnexionCount);
                store.commit('SET_SENSORS_CONNEXION_AREA_COUNT', response.data.data.sensorsConnexionAreaCount);
                store.commit('SET_ACTUAL_AREA', response.data.data.administrativeAreaLvl1);
                store.commit('SET_SENSOR_ANALYTIC_LOADING');
            })
            .catch(error => {
                const requestStatus = [error.message, error.error_code];
                store.commit('BAD_REQUEST', requestStatus);
                store.commit('SET_SENSOR_ANALYTIC_LOADING');
            });
    },
};

const getters = {
    sensorsConnexionCount: state => {
        return state.sensorsConnexionCount;
    },
    sensorsConnexionAreaCount: state => {
        return state.sensorsConnexionAreaCount;
    },
    actualArea: state => {
        return state.actualArea;
    },
    isSensorAnalyticLoading: state => {
        return state.isSensorAnalyticLoading;
    },
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
