import Vue from 'vue'
import Vuex from 'vuex'
import login from './modules/login'
import sensor from "./modules/sensor/sensor";
import report from "./modules/report/report";
import risk from "./modules/risk/risk";
import rollingRisk from "./modules/risk/rollingRisk";
import user from "./modules/user/user"
import account from "@/store/modules/account/account";
import apiKey from "@/store/modules/apiKey";
import userReport from "@/store/modules/userReport/userReport";
import globalConcentration from "@/store/modules/globalConcentration/globalConcentration";
import filters from "@/store/modules/filters/filters";
import reportType from "@/store/modules/reportType/reportType";
import notificationSettings from "@/store/modules/notificationSettings/notificationSettings";
import sensorAnalytic from "@/store/modules/sensorAnalytic/sensorAnalytic";
import analytics from "@/store/modules/analytics/analytics";
import i18n from "@/i18n";

Vue.use(Vuex);

const store = new Vuex.Store({
	modules: {
		login,
		sensor,
		report,
		risk,
		user,
		account,
		apiKey,
		rollingRisk,
		userReport,
		globalConcentration,
		analytics,
		filters,
		reportType,
		notificationSettings,
		sensorAnalytic
	},
	state: {
		packageVersion: process.env.VUE_APP_VERSION,
		appLanguage: localStorage.getItem("appLanguage") || process.env.VUE_APP_I18N_LOCALE || 'fr_fr'
	},
	getters: {
		appVersion: (state) => {
			return state.packageVersion
		},
		getAppLanguage: (state) => state.appLanguage
	},
	mutations: {
		SET_APP_LANGUAGE(state, language) {
			state.appLanguage = language;
			localStorage.setItem("language", language); // Whenever we change the appLanguage we save it to the localStorage
		}
	},
	actions: {
		setLanguage: async (store, language) => {
			i18n.locale = language
			store.commit('SET_APP_LANGUAGE', language)
		}
	}
});

export default store

