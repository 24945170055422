<template>
    <div id="navbar" class="bg-white fixed-top">
        <b-navbar :toggleable="false" type="light">
            <b-navbar-brand href="#" class="mr-auto">
                <slot></slot>
            </b-navbar-brand>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="ml-auto">
                    <div class="d-flex d-inline justify-content-center text-center align-items-center add-cursor"
                         href="/">
                        <b-img src="@/assets/images/icon/logo_header.svg" @click="$router.go('dashboard')"
                               alt="logo lifyair" height="30"/>
                    </div>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
import Vuex from "vuex";

export default {
    name: "TheNavbar",
    data() {
        return {langs: ['fr', 'en']}
    },
    methods: {
        ...Vuex.mapActions('login', {
            logout: 'logout'
        }),
    }
}
</script>

<style lang="scss" scoped>
.navbar {
    z-index: 1002;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
}
</style>
