import Vue from "vue"
import moment from 'moment';
import i18n from "@/i18n";

function capitalize(value){
	if (!value) return ''
	value = value.toString()
	return value.charAt(0).toUpperCase() + value.slice(1)
}

Vue.filter('capitalize', function (value) {
	return capitalize(value)
});

Vue.filter('upperCase', function (value) {
	return value.toUpperCase()
});

Vue.filter('isEmptyObject', function (object) {
	return Object.keys(object).length !== 0
});

Vue.filter('fromNow', function (date) {
	moment.locale(i18n.locale);
	const utcDate = moment.utc(date);
	return utcDate.fromNow();
});

Vue.filter('convertDate', function (date) {
	let utcDate = moment.utc(date).format('YYYY-MM-DD HH:mm:ss');
	var stillUtc = moment.utc(utcDate).toDate();
	return moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss')
});

Vue.filter('formattedDate', function (date) {
	return moment(date).format('LL')
});

Vue.filter('formatMonth', function(month) {
	if(month.length > 4){
		return month.substring(0,3).concat('.');
	}
	return month
})

Vue.filter('formatSensorName', function(name) {
	if(name.length > 15){
		return name.substring(0, 12) + '...';
	}
	return name;
})
Vue.filter('abbreviateNumber', function (number, decPlaces = 2) {
		decPlaces = Math.pow(10, decPlaces)

		// Enumerate number abbreviations
		const abbrev = ['k', 'm', 'b', 't']

		// Go through the array backwards, so we do the largest first
		for (let i = abbrev.length - 1; i >= 0; i--) {
			// Convert array index to "1000", "1000000", etc
			const size = Math.pow(10, (i + 1) * 3)

			// If the number is bigger or equal do the abbreviation
			if (size <= number) {
				// Here, we multiply by decPlaces, round, and then divide by decPlaces.
				// This gives us nice rounding to a particular decimal place.
				number = Math.round((number * decPlaces) / size) / decPlaces

				// Handle special case where we round up to the next abbreviation
				if (number === 1000 && i < abbrev.length - 1) {
					number = 1
					i++
				}

				// Remove decimals when number > 100
				if (Math.floor(number) > 99) number = Math.ceil(number);
				// Add the letter for the abbreviation
				number += abbrev[i]

				// We are done... stop
				break
			}
		}

		return number
});

// Vue.filter('minuteToHourMinute', function (value) {
// 	let num = value;
// 	let hours = (num / 60);
// 	let rhours = Math.floor(hours);
// 	let minutes = (hours - rhours) * 60;
// 	let rminutes = Math.round(minutes);
// 	if (rhours === 0) {
// 		return rminutes + " mn";
// 	} else {
// 		return rhours + " hr " + rminutes + " mn";
//
// 	}
// });
// Vue.filter('displayDateDiffHour', function (date) {
// 	const formatDate = new Date(date);
// 	let hours = moment(new Date()).diff(formatDate, 'hours');
//
// 	if (hours < 1) {
// 		let minutes = moment(new Date()).diff(formatDate, 'minutes');
// 		if (minutes < 0) {
// 			return ' ' + i18n.tc('time.lessThanMinute')
// 		} else {
// 			return minutes + ' ' + i18n.tc('time.day',minutes);
// 		}
// 	} else if (hours > 24) {
// 		let days = moment(new Date()).diff(formatDate, 'days');
// 		return days + ' ' + i18n.tc('time.day',days)
// 	} else {
// 		return hours + ' ' + i18n.tc('time.hour',hours) + (hours > 1 ? 's' : '')
// 	}
// });
// Vue.filter('uppercase', function (value) {
// 	if (!value) return '';
// 	return value.toUpperCase()
// });
// Vue.filter('formatTimeStringToSimpleHour', function (value) {
// 	if (!value) return '';
// 	let dataTime = value
// 	let splitDatatime = dataTime.split(':')
// 	let splitDataTimeValue = splitDatatime[0]
// 	if (splitDataTimeValue.substring(0, 1) === '0') {
// 		dataTime = splitDataTimeValue.substring(1)
// 	}else {
// 		dataTime = splitDataTimeValue
// 	}
// 	return dataTime
// });
//
// Vue.filter('dateDayMonth', function(value) {
// 	return moment(value).format('DD/MM');
// });
//
// Vue.filter('minutesToHours', function(minutes) {
// 	return Math.round(minutes/60);
// });
