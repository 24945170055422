import axios from "../../plugins/axios";
import authHeader from "./authHeader";

export default {
    getUserReports,
    getUserReportsHistory
}

function getUserReports(data) {
    let formData = new FormData()
    const cloneData = { ...data };

    if(cloneData.params.clusters !== undefined){
        for (let i = 0; i < cloneData.params.clusters.length; i++) {
            formData.append('clusters[]', JSON.stringify(cloneData.params.clusters[i]))
        }
    }
    delete cloneData.params.clusters
    return axios.post('/map/user-reports', formData, {...authHeader.classicalHeader(), ...cloneData})
}

function getUserReportsHistory(data) {
    let formData = new FormData()
    const cloneData = { ...data };
    if(cloneData.params.clusters !== undefined){
        for (let i = 0; i < cloneData.params.clusters.length; i++) {
            formData.append('clusters[]', JSON.stringify(cloneData.params.clusters[i]))
        }
    }
    delete data.params.clusters
    return axios.post('/map/user-reports/history', formData, {...authHeader.classicalHeader(), ...data})
}


