<template>
    <div class="global-concentration-chart">
        <LineChart
            v-if="options"
            :chart-options="chartOptions"
            :series="chartData"
            :height="'200px'"
            class="global-concentration-chart"/>
    </div>
</template>

<script>
import moment from 'moment';
import {apexchartsOptions} from "@/services/charts/apexchartsOptions";
import LineChart from "@/components/charts/LineChart";
import Vuex from "vuex";

export default {
    name: "GlobalConcentrationChart",
    components: {
        LineChart
    },
    data() {
        return {
            series: [{
                name: 'Global Concentration',
                data: []
            }],
            options: null,
            isDataComplete: true,
            gcValues: []
        }
    },
    props: {
        globalConcentrations: Array,
    },
    computed: {
        ...Vuex.mapGetters('filters', {
            filters: 'filters',
        }),
        chartData() {
            return this.series;
        },
        chartOptions() {
            return this.options;
        }
    },
    methods: {
        setChartData() {
            let values = [];
            let globalConcentrationEvolution = [...this.globalConcentrations];

            let now = moment().subtract(1, 'hours');
            let oneDayAgo = moment();
            oneDayAgo = oneDayAgo.subtract(25, 'hours');
            let oneDayHours = [];

            if (this.filters.date) {
                const time = "23:59:59";
                now = moment(this.filters.date + ' ' + time);
                oneDayAgo = moment(this.filters.date + ' ' + time);
                oneDayAgo = oneDayAgo.subtract(24, 'hours');
            }

            while (oneDayAgo.add(1, 'hours').diff(now) <= 0) {
                oneDayAgo.set({minute:0,second:0,millisecond:0})
                oneDayHours.push(oneDayAgo.format('YYYY-MM-DD HH:mm:ss'));
            }

            oneDayHours.forEach(date => {
                let report =
                    globalConcentrationEvolution.find(item => this.$options.filters.convertDate(item.createdAt) === date);
                let value = null;
                if (report !== undefined) {
                    if (report.value === null || report.value === 0) {
                        value = 0.3
                    } else if (parseInt(report.value) > 0) {
                        value = parseInt(report.value)
                    }
                    this.gcValues.push(value);
                } else {
                    this.isDataComplete = false;
                    value = null;
                }

                values.push({x: date, y: value})
            })

            this.series = [{
                name: this.$options.filters.capitalize(this.$t('risk.this')),
                data: values
            }];
            this.updateIsComplete();
        },
        updateIsComplete() {
            this.$emit('updateIsComplete', this.isDataComplete)
        },
        setChartOptions() {
            let options = apexchartsOptions.globalConcentrationLineChart;
            const min = 0;
            const max = Math.max(...this.gcValues);
            if (min === max) {
                options.yaxis.min = min;
                options.yaxis.max = max + 1;
            } else {
                options.yaxis.min = min;
                options.yaxis.max = max;
            }
            this.options = options;
        }
    },
    mounted() {
        this.setChartData();
        this.setChartOptions();
    },
}
</script>

<style lang="scss">
.global-concentration-chart {
    width: 100%;
    height: 200px;

    @media only screen and (max-width: 600px) {
        width: 100% !important;
    }

    &.widget {
        height: 80%;
    }
}
</style>
