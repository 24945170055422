export default {
    allergizingPowerByPollen,
    getPollensByCategory,
    getCategoryByPollen,
    getColorByValue,
    getBackgroundColorByValue,
    getLevelString,
    getHexColorByValue,
    formatReports,
    formatUserReports,
    getTextColorByValue,
    getRGBColorByValue
}

const color = {
    "High": "#F26666",
    "Medium": "#F2CF63",
    "Low": "#4BA68D",
}

const colorByValue = {
    'off' : "lightgrey",
    null : "cadetblue",
    "0" : "cadetblue",
    "1" : "gold",
    "2" : "orange-sandybrown",
    "3" : "indianred",
    "4" : "indianred",
}
const hexColorByValue = {
    'off' : "#919496",
    null : "#4BA68D",
    "0" : "#4BA68D",
    "1" : "#F6C90F",
    "2" : "#FF9C5B",
    "3" : "#d9534f",
    "4" : "#d9534f",
}

const rbgColorByValue = {
    0:{r: 75, g: 166, b: 141},
    1:{r: 246, g: 201, b: 15},
    2:{r: 255, g: 156, b: 91},
    3:{r: 217, g: 83, b: 79},
    null:{r: 212, g: 212, b: 212},
}

const backgroundColorByValue = {
    null : "dimgrey",
    "0" : "green-whitesmoke",
    "1" : "oldlace",
    "2" : "orange-seashell",
    "3" : "pink-mistyrose",
}

const levelString = {
    null : "null",
    "0" : "none",
    "1" : "low",
    "2" : "medium",
    "3" : "high",
    "4" : "high",
}

const textColorByRisk = {
    null : "snow",
    "none" : "snow",
    "0" : "snow",
    "1" : "darkslategrey",
    "2" : "darkslategrey",
    "3" : "snow",
}

const pollenAllergizingPower = {
    "alder": {allergizingPower: 'High', value: 3, color: color['High']},
    "birch": {allergizingPower: 'High', value: 3, color: color['High']},
    "hornbeam": {allergizingPower: 'High', value: 3, color: color['High']},
    "chestnut": {allergizingPower: 'Medium', value: 2, color: color['Medium']},
    "hazel": {allergizingPower: 'High', value: 3, color: color['High']},
    "cypress": {allergizingPower: 'High', value: 3, color: color['High']},
    "ash": {allergizingPower: 'High', value: 3, color: color['High']},
    "olive tree": {allergizingPower: 'High', value: 3, color: color['High']},
    "olive": {allergizingPower: 'High', value: 3, color: color['High']},
    "plane-tree": {allergizingPower: 'Medium', value: 2, color: color['Medium']},
    "poplar": {allergizingPower: 'Low', value: 1, color: color['Low']},
    "oak": {allergizingPower: 'Medium', value: 2, color: color['Medium']},
    "willow": {allergizingPower: 'Medium', value: 2, color: color['Medium']},
    "linden": {allergizingPower: 'High', value: 3, color: color['High']},
    "ragweed": {allergizingPower: 'High', value: 3, color: color['High']},
    "mugwort": {allergizingPower: 'High', value: 3, color: color['High']},
    "plantain": {allergizingPower: 'Medium', value: 2, color: color['Medium']},
    "sorrel": {allergizingPower: 'Medium', value: 2, color: color['Medium']},
    "nettle": {allergizingPower: 'Low', value: 1, color: color['Low']},
    "pellitory": {allergizingPower: 'High', value: 3, color: color['High']},
    "timothy": {allergizingPower: 'High', value: 3, color: color['High']},
    "fescue": {allergizingPower: 'High', value: 3, color: color['High']},
    "couch-quack": {allergizingPower: 'High', value: 3, color: color['High']},
    "orchard": {allergizingPower: 'High', value: 3, color: color['High']},
    "vernal": {allergizingPower: 'High', value: 3, color: color['High']},
    "barley": {allergizingPower: 'High', value: 3, color: color['High']},
    "juniper": {allergizingPower: 'Low', value: 1, color: color['Low']},
    "maple": {allergizingPower: 'Medium', value: 2, color: color['Medium']},
    "cedar": {allergizingPower: 'High', value: 3, color: color['High']},
    "velvetgrass": {allergizingPower: 'High', value: 3, color: color['High']},
    "wheat": {allergizingPower: 'High', value: 3, color: color['High']},
    "grass": {allergizingPower: 'High', value: 3, color: color['High']},
    "beech": {allergizingPower: 'Medium', value: 2, color: color['Medium']},
}

const pollensByCategory = {
    "tree": [
        "alder",
        "birch",
        "hornbeam",
        "chestnut",
        "hazel",
        "cypress",
        "ash",
        "olive tree",
        "olive",
        "plane-tree",
        "poplar",
        "oak",
        "willow",
        "linden",
        "juniper",
        "maple",
        "cedar",
        "beech"
    ],
    "grass": [
        "timothy",
        "orchard",
        "wheat",
        "vernal",
        "fescue",
        "couch-quack",
        "velvetgrass",
        "barley",
    ],
    "weed": [
        "sorrel",
        "ragweed",
        "mugwort",
        "plantain",
        "nettle",
        "pellitory"
    ],
}

// const urticaceae = ["nettle", "pellitory"]
//
// function isUrticaceae(pollen) {
//     return urticaceae.includes(pollen);
// }

function allergizingPowerByPollen(pollen) {
    return pollenAllergizingPower[pollen];
}

function getPollensByCategory(category) {
    return pollensByCategory[category];
}

function getCategoryByPollen(pollen) {
    let pollenCategory = null;
    Object.keys(pollensByCategory).forEach((category) => {
        if (category === 'grass') {
            pollenCategory = 'grass';
        } else if(pollensByCategory[category].includes(pollen)) {
            pollenCategory = category;
        }
    })
    return pollenCategory;
}

function getColorByValue(value) {
    return colorByValue[value];
}

function getTextColorByValue(value) {
    return textColorByRisk[value];
}

function getBackgroundColorByValue(value) {
    return backgroundColorByValue[value];
}

function getLevelString(value) {
    return levelString[value];
}

function getHexColorByValue(value) {
    return hexColorByValue[value];
}

function getRGBColorByValue(value) {
    return rbgColorByValue[value];
}

function formatUserReports(reports) {
    reports.forEach((userReport) => {
        let data = userReport.reportTypes.filter(type => type.category !== 'grass');
        const grasses = userReport.reportTypes.filter(type => type.category === 'grass');
        if (grasses.length > 0) data.push({name: 'grass', category: 'grass'})
        userReport.reportTypes = data;
    })
    return reports;
}

function formatReports(reports) {
    let data = reports.filter(report => report.category !== 'grass');
    // const urticaceaes = reports.filter(report => isUrticaceae(report.name));
    const grasses = reports.filter(report => report.category === 'grass');
    // if (urticaceaes.length > 0) data.push(processSpecialCategories(urticaceaes, 'weed' ,'urticaceae'))
    if (grasses.length > 0) data.push(processSpecialCategories(grasses, 'grass', 'grass'))
    return formatRiskScaleReports(data);
}

function processSpecialCategories(reports, category, subcategory) {
    let risks = [];
    let dates = [];
    reports.forEach((report) => {
        risks.push(report.riskLevel)
        dates.push(report.createdAt)
    })
    dates.sort(function(a,b){
        return new Date(b.date) - new Date(a.date);
    });
    return {
        createdAt: dates[0],
        riskLevel: Math.max(...risks),
        name: subcategory,
        category: category
    };
}

function formatRiskScaleReports(reports) {
    let risks = [];
    reports.forEach((report) => {
        switch (report.riskLevel) {
            case 1:
            case 2:
            case 3:
                report.riskLevel = 1;
                break;
            case 4:
                report.riskLevel = 2;
                break;
            case 5:
                report.riskLevel = 3;
                break;
            default:
                break;
        }
        risks.push(report.riskLevel);
    })
    return {reports : reports, globalRisk: Math.max(...risks)};
}