import api from '@/services/api/index.js'

const state = {
    sensors: [],
    sensorList: [],
    getSensors: [],
    accountsSensors: [],
    loadingStatus: false,
    globalMaxRisk: null,
    installationDate: null
};

const mutations = {
    SET_SENSORS: (state, sensors) => {
        state.sensors = sensors;
    },
    SET_SENSOR_LIST: (state, sensorList) => {
        state.sensorList = sensorList;
    },
    SET_SENSOR_BY_ACCOUNT: (state, sensors) => {
        state.accountsSensors = sensors;
    },
    SET_SENSORS_ACC: (state, sensors) => {
        state.getSensors = sensors;
    },
    SET_INSTALLATION_DATE: (state, installationDate) => {
        state.installationDate = installationDate;
    },
    BAD_REQUEST: (state, requestStatus) => {
        state.request = {status: requestStatus[1], message: requestStatus[0]}
    },
    SENSORS_IS_LOADING_STATUS: (state) => {
        state.loadingStatus = !state.loadingStatus
    },
};
const actions = {
    setSensors: async (store, data) => {
        store.commit('SENSORS_IS_LOADING_STATUS');
        await api.sensor.getUserSensors(data)
            .then(response => {
                store.commit('SET_SENSORS', response.data.data.sensors);
                store.commit('SET_INSTALLATION_DATE', response.data.data.installationDate);
                if (state.sensorList.length === 0) {
                    store.commit('SET_SENSOR_LIST', response.data.data.sensors);
                }
                store.commit('SENSORS_IS_LOADING_STATUS');
            });
    },
    setSensorsByAccount: async (store, data) => {
        await api.sensor.getUserSensors(data)
            .then(response => {
                store.commit('SET_SENSOR_BY_ACCOUNT', response.data.data.sensors);
            });
    },
    setSensorsAcc: async (store) => {
        await api.sensor.getSensors()
            .then(response => {
                store.commit('SET_SENSORS_ACC', response.data.data.sensors);
            })
    }
};

const getters = {
    sensors: state => {
        return state.sensors;
    },
    sensorList: state => {
        return state.sensorList;
    },
    accountsSensors: state => {
        return state.accountsSensors;
    },
    getSensors: state => {
        return state.getSensors;
    },
    getSensorsById: (state) => (id) => {
        let sensor = state.sensors.filter(sensor => sensor.serialNumber === id)
        if (sensor.length > 0) {
            return sensor[0]
        } else {
            return null
        }
    },
    loadingStatus: state => {
        return state.loadingStatus
    },
    getRequest: state => {
        return state.request
    },
    globalMaxRisk: state => {
        return state.globalMaxRisk
    },
    getLatestSensorUpdated() {
        return new Date(
            Math.max(
                ...state.sensors.map(element => {
                    return new Date(element.lastUpdate);
                }),
            ),
        )
    },
    areSensorsInDeployment() {
        const inDeployment = state.sensors.filter(item => item.status === 'deploy').length > 0;
        return inDeployment && state.sensors.filter(item => item.status !== 'deploy').length <= 0;
    },
    getInstallationDate: state => {
        return state.installationDate;
    }
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
