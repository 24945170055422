<template>
    <apexchart
        type="bar"
        :width="width"
        :height="height"
        :options="chartOptions"
        :series="series"
        @dataPointSelection="handleAction"
        ref="barChart"/>
</template>

<script>
export default {
    name: "BarChart",
    props: {
        chartOptions: {
            type: Object,
            default: () => {
                return {}
            }
        },
        series: {
            type: Array,
            default: () => {
                return [{
                    name: this.$options.filters.capitalize(this.$t('risk.this')),
                    data: []
                }]
            }
        },
        width: {
            type: String,
            default: '100%'
        },
        height: {
            type: String,
            default: 'auto'
        },
        handleClick: {
            type: String,
            default: ''
        }
    },
    methods: {
        handleAction(event, chartContext, config) {
            this.$emit(this.handleClick, config.dataPointIndex);
        }
    }
}
</script>

<style lang="scss">
</style>
