<template>
    <div class="row justify-content-center bg-white m-0" id="popover-evolution">
        <div v-if="globalRisksHistoryLoading">
            <div class="text-center m-5">
                <b-spinner variant="primary" label="loader" type="grow"></b-spinner>
            </div>
        </div>
        <b-col cols="12"
               class="p-0 mt-2 mb-3"
               v-if="!isDataComplete && !globalRisksHistoryLoading && filters.pollens.length === 0">
            <div class="basic-card bg-pink-mistyrose clr-indianred">
                <b-row align-v="center" align-h="around">
                    <b-col cols="2" class="m-0 text-center">
                        <IconBase viewBox="0 0 21 20" width="40" height="40">
                            <IconClock/>
                        </IconBase>
                    </b-col>
                    <b-col cols="10" class="m-0 pl-0 caption clr-indianred">
                        {{ $t('emptyState.riskEvolutionNotComplete') }}
                    </b-col>
                </b-row>
            </div>
        </b-col>
        <RiskEvolutionBarChart v-if="!globalRisksHistoryLoading" @updateIsComplete="updateIsComplete"
                                        :risks="globalRisksHistory" :is-widget="isWidget"/>
    </div>
</template>

<script>
import RiskEvolutionBarChart from "@/components/dashboard/rollingRisk/RollingRiskEvolutionBarChart";
import Vuex from "vuex";
import IconBase from "@/components/common/icons/IconBase";
import IconClock from "@/components/common/icons/IconClock";

export default {
    name: "RollingRiskEvolution",
    components: {
        IconClock,
        IconBase,
        RiskEvolutionBarChart
    },
    props: {
        isDashboard: {
            type: Boolean,
            default: false
        },
        isWidget: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            isDataComplete: true
        }
    },
    methods: {
        updateIsComplete(val) {
            this.isDataComplete = val;
        }
    },
    computed: {
        ...Vuex.mapGetters('rollingRisk', {
            globalRisksHistory: 'globalRisksHistory',
            globalRisksHistoryLoading:'globalRisksHistoryLoading'
        }),
        ...Vuex.mapGetters('filters', {
            filters: 'filters'
        }),
    }
}
</script>

<style lang="scss" scoped>
</style>
