<template>
    <div class="evolution-chart">
        <BarChart :chart-options="options"
                  :height="'200px'"
                  :handle-click="'selectDate'"
                  @selectDate="selectDate"
                  :series="chartData" class="evolution-chart"/>
    </div>
</template>

<script>
import BarChart from "@/components/charts/BarChart";
import moment from 'moment';
import {apexchartsOptions} from "@/services/charts/apexchartsOptions";
import Vuex from "vuex";

export default {
    name: "UserReportsEvolutionBarChart",
    components: {
        BarChart
    },
    data() {
        return {
            series: [{
                name: this.$options.filters.capitalize(this.$t('common.title.popup.useReportsEvolution')),
                data: []
            }],
            options: apexchartsOptions.userReportsEvolutionBar,
        }
    },
    props: {
        userReports: Array
    },
    computed: {
        ...Vuex.mapGetters('filters', {
            filters: 'filters',
        }),
        chartData() {
            return this.series;
        }
    },
    methods: {
        ...Vuex.mapActions('filters', {
            setFilters: 'setFilters',
        }),
        selectDate(dataIndex) {
            this.setFilters({date: this.chartData[0].data[dataIndex].x, fromMap: false})
        },
        setChartData() {
            let values = [];
            let userReportsEvolution = [...this.userReports];

            let now = moment().subtract(1, 'days');
            let twoWeeksAgo = moment();
            twoWeeksAgo = twoWeeksAgo.subtract(15, 'days');
            let twoWeeksDates = [];

            if (this.filters.date) {
                now = moment(this.filters.date);
                twoWeeksAgo = moment(this.filters.date);
                twoWeeksAgo = twoWeeksAgo.subtract(15, 'days');
            }

            while (twoWeeksAgo.add(1, 'days').diff(now) <= 0) {
                twoWeeksDates.push(twoWeeksAgo.format('YYYY-MM-DD'));
            }

            twoWeeksDates.forEach(date => {
                let report = userReportsEvolution
                    .find(item => moment(item.day, 'YYYY-MM-DD').format('YYYY-MM-DD') === date)
                let count = null;
                if (report !== undefined) {
                    count = report.count;
                }

                values.push({x: date, y: count})
            })

            this.series = [{
                name: this.$options.filters.capitalize(this.$t('common.title.popup.useReportsEvolution')),
                data: values
            }];
        }
    },
    mounted() {
        this.setChartData();
    },
}
</script>

<style lang="scss">
.evolution-chart {
    width: 100%;

    @media only screen and (max-width: 600px) {
        width: 100% !important;
    }

    &.widget {
        height: 80%;
    }
}
</style>
