const state = {
    apiKey: null,
    apiKeySecret: null
};

const mutations = {
    SET_API_KEY: (state, apiKey) => {
        state.apiKey = apiKey
    },
    SET_API_KEY_SECRET: (state, apiKeySecret) => {
        state.apiKeySecret = apiKeySecret
    },
    RESET_API_CREDENTIALS: (state) => {
        state.apiKey = null;
        state.apiKeySecret = null;
    }
};
const actions = {
    setApiKey: async (store, data) => {
        store.commit('SET_API_KEY', data)
    },
    setApiKeySecret: async (store, data) => {
        store.commit('SET_API_KEY_SECRET', data)
    },
    resetCredentials: async (store) => {
        store.commit('RESET_API_CREDENTIALS');
    }
};

const getters = {
    getApiKey: state => {
        return state.apiKey
    },
    getApiKeySecret: state => {
        return state.apiKeySecret
    },
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}