import api from '@/services/api/index.js'
import Vue from "vue";
import i18n from "@/i18n";

const state = {
    sensorsList:[],
    accountList:[],
    pollenList:[],
    globalAnalytics:{
        daysOfPollenPresence:{},
        highestRiskSensor:{},
        yearlyConcentration:{},
    },
    pollenAnalytics:{
        presenceOfPollen:{},
        presenceOfPollenCompared:{}
    },
    sensorAnalytics:{
        presenceOfPollen:{},
        presenceOfPollenCompared:{}
    },
    accountAnalytics: [],
    accountUsersAnalytics: [],
    sensorsOptions: [],
    accountOptions: [],
    pollenOptions: [],
    loadingSensorStatus:false,
    loadingAccountStatus:false,
    loadingAnalytics:true,
};

const mutations = {
    SET_SENSOR_OPTIONS: (state, sensorOptions) => {
        state.loadingSensorStatus = true
        let newOptions = [
            { value: '', text: '-- Choisir un Capteur --' }
        ]
        for (let i = 0 ; i < sensorOptions.length;i++){
            let name = sensorOptions[i].sensorName
            if(!name){
                name = sensorOptions[i].serialNumber
            }
            newOptions.push({value:sensorOptions[i].serialNumber,text:name});
        }

        state.sensorsOptions = newOptions
        state.sensorsList = sensorOptions;
        state.loadingSensorStatus = false
    },
    SET_ACCOUNT_OPTIONS: (state, accountOptions) => {
        state.loadingAccountStatus = true
        let newOptions = [
            { value: '', text: '-- Choisir un Capteur --' }
        ]
        for (let i = 0 ; i < accountOptions.length;i++){
            newOptions.push({value:accountOptions[i].name,text:accountOptions[i].name});
        }
        newOptions =
        state.accountOptions = newOptions
        state.accountList = accountOptions;
        state.loadingAccountStatus = false
    },
    SET_POLLEN_OPTIONS: (state, pollenOptions) => {
        let newOptions = []
        for (let i = 0 ; i < pollenOptions.length;i++){
            newOptions.push({
                value: pollenOptions[i].name,
                text: Vue.filter('capitalize')(i18n.t('pollen.type.' + pollenOptions[i].name)) + ' - ' + Vue.filter('capitalize')(i18n.t('pollen.category.' + pollenOptions[i].category))
            })
        }
        state.pollenOptions = [...state.pollenOptions,...newOptions]
        state.pollenList = pollenOptions;
    },
    SET_GLOBAL_ANALYTICS: (state, data) => {
        if (Object.keys(data).length > 0 || data.length > 0) {
            state.globalAnalytics.daysOfPollenPresence = data.daysOfPollenPresence;
            state.globalAnalytics.highestRiskSensor = data.highestRiskSensor;
            state.globalAnalytics.yearlyConcentration = data.yearlyConcentration;
        } else {
            state.globalAnalytics.daysOfPollenPresence = [];
            state.globalAnalytics.highestRiskSensor = [];
            state.globalAnalytics.yearlyConcentration = [];
        }
    },
    SET_POLLEN_ANALYTICS: (state, data) => {
        if (Object.keys(data).length > 0) {
            state.pollenAnalytics.presenceOfPollen = data.presenceOfPollen;
            state.pollenAnalytics.presenceOfPollenCompared = data.presenceOfPollenCompared;
        } else {
            state.pollenAnalytics.presenceOfPollen = [];
            state.pollenAnalytics.presenceOfPollenCompared = [];
        }
    },
    SET_SENSOR_ANALYTICS: (state, data) => {
        if (Object.keys(data).length > 0) {
            state.sensorAnalytics.presenceOfPollen = data.presenceOfPollen;
            state.sensorAnalytics.presenceOfPollenCompared = data.presenceOfPollenCompared;
        } else {
            state.sensorAnalytics.presenceOfPollen = [];
            state.sensorAnalytics.presenceOfPollenCompared = [];
        }
    },
    BAD_REQUEST: (state, requestStatus) => {
        state.request = {status: requestStatus[1], message: requestStatus[0]}
    },
    SENSORS_IS_LOADING_STATUS: (state) => {
        state.loadingStatus = !state.loadingStatus
    },
    ANALYTICS_ARE_LOADING_STATUS: (state, status) => {
        state.loadingAnalytics = status
    },
    SET_ACCOUNT_ANALYTICS: (state, data) => {
        state.accountAnalytics = data;
    },
    SET_ACCOUNT_USERS_ANALYTICS: (state, data) => {
        state.accountUsersAnalytics = data;
    }
};
const actions = {
    setSensorOptions: async (store, data = null) => {
        await api.analytics.getSensorOptions(data)
            .then(response => {
                store.commit('SET_SENSOR_OPTIONS', response.data.data);
            });
    },
    setAccountAnalytics: async(store, data) => {
        await api.analytics.getAccountAnalytics(data)
            .then( response => {
                store.commit('SET_ACCOUNT_ANALYTICS', response.data.data);
            });
    },
    setAccountUsersAnalytics: async(store, data) => {
        await api.analytics.getAccountUsersAnalytics(data)
            .then(response => {
                store.commit('SET_ACCOUNT_USERS_ANALYTICS', response.data.data);
            })
    },
    setAccountOptions: async (store) => {
        await api.analytics.getAccountOptions()
            .then(response => {
                store.commit('SET_ACCOUNT_OPTIONS', response.data.data);
            });
    },
    setPollenOptions: async (store) => {
        await api.analytics.getPollenOptions()
            .then(response => {
                store.commit('SET_POLLEN_OPTIONS', response.data.data);
            })
            .catch(error => {
                const requestStatus = [error.message, error.error_code];
                store.commit('BAD_REQUEST', requestStatus);
            });
    },
    setGlobalAnalytics: async (store, filters) => {
        store.commit('ANALYTICS_ARE_LOADING_STATUS', true);

        await api.analytics.getGlobalAnalytics(filters)
            .then(response => {
                if (filters[0] === 'global') {
                    store.commit('SET_GLOBAL_ANALYTICS', response.data.data);
                } else if (filters[0] === 'pollen') {
                    store.commit('SET_POLLEN_ANALYTICS', response.data.data);
                } else if (filters[0] === 'sensor') {
                    store.commit('SET_SENSOR_ANALYTICS', response.data.data);
                }
                store.commit('ANALYTICS_ARE_LOADING_STATUS', false);
            }).catch(() => {
                store.commit('ANALYTICS_ARE_LOADING_STATUS', false);
            });
    },
};

const getters = {
    sensorOptions: state => {
        return state.sensorsOptions
    },
    accountOptions: (state) => {
        return state.accountOptions
    },
    pollenOptions: (state) => {
        return state.pollenOptions
    },
    globalAnalytics: (state) => {
        return state.globalAnalytics
    },
    pollenAnalytics: (state) => {
        return state.pollenAnalytics
    },
    sensorAnalytics: (state) => {
        return state.sensorAnalytics
    },
    loadingSensorStatus: state => {
        return state.loadingSensorStatus
    },
    loadingAccountStatus: state => {
        return state.loadingAccountStatus
    },
    loadingAnalytics: state => {
        return state.loadingAnalytics
    },
    getSensorList: state => {
        return state.sensorsList
    },
    getAccountAnalytics: state => {
        return state.accountAnalytics
    },
    getAccountUsersAnalytics: state => {
        return state.accountUsersAnalytics;
    }
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
