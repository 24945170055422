import axios from "axios";
export default {
    getActualWeather,
}

function getActualWeather(coordinate) {
    const instance = axios.create({
        baseURL: 'https://api.openweathermap.org/data/2.5',
        timeout: 3000,
    });
    let token = 'cc28b2953e46b2f1c8f20f215fe38064'

    return instance.get('/weather?lat='+coordinate[0]+'&lon='+coordinate[1]+'&appid='+token)

}
