import api from '@/services/api/index.js'

const state = {
    notificationSettingsCount: 0,
    areNotificationSettingsLoading: false,
    request: {
        data: {},
        error: {}
    }
};

const mutations = {
    SET_NOTIFICATION_SETTINGS_COUNT: (state, notificationSettingsCount) => {
        state.notificationSettingsCount = notificationSettingsCount;
    },
    SET_NOTIFICATION_SETTINGS_LOADING: (state) => {
        state.areNotificationSettingsLoading = !state.areNotificationSettingsLoading
    },
};

const actions = {
    setNotificationSettings: async (store, params) => {
        store.commit('SET_NOTIFICATION_SETTINGS_LOADING');
        await api.notificationSettings.getNotificationSettingsCount(params)
            .then(response => {
                store.commit('SET_NOTIFICATION_SETTINGS_COUNT', response.data.data.notificationSettingsCount);
                store.commit('SET_NOTIFICATION_SETTINGS_LOADING');
            })
            .catch(error => {
                const requestStatus = [error.message, error.error_code];
                store.commit('BAD_REQUEST', requestStatus);
                store.commit('SET_NOTIFICATION_SETTINGS_LOADING');
            });
    },
};

const getters = {
    notificationSettingsCount: state => {
        return state.notificationSettingsCount;
    },
    areNotificationSettingsLoading: state => {
        return state.areNotificationSettingsLoading;
    },
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
