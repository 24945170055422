import axios from "../../plugins/axios";
import authHeader from "./authHeader";

export default {
    getActualRollingRisks,
    getRollingRisksHistory,
    getRollingRisksByCriteria,
    getRollingRiskHistoryByCriteria
}

function getActualRollingRisks() {
    return axios.get('/rolling-risks', authHeader.classicalHeader())
}

function getRollingRisksByCriteria(params) {

    return axios.get('/map/rolling-risks', {...authHeader.classicalHeader(), ...params})
}


function getRollingRiskHistoryByCriteria(params) {

    return axios.get('/map/rolling-risks/history', {...authHeader.classicalHeader(), ...params})
}

function getRollingRisksHistory() {

    return axios.get('/rolling-risks/history', authHeader.classicalHeader())
}
