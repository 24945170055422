import api from '@/services/api/index.js'

const state = {
    userReports: [],
    areUserReportsLoading: false,
    userReportsHistory: [],
    areUserReportsHistoryLoading: false,
    showUserReports: false,
    request: {
        data: {},
        error: {}
    }
};

const mutations = {
    SET_USER_REPORTS: (state, userReports) => {
        state.userReports = userReports;
    },
    SET_USER_REPORTS_LOADING: (state) => {
        state.areUserReportsLoading = !state.areUserReportsLoading
    },
    SET_USER_REPORTS_HISTORY: (state, userReportsHistory) => {
        state.userReportsHistory = userReportsHistory;
    },
    SET_USER_REPORTS_HISTORY_LOADING: (state) => {
        state.areUserReportsHistoryLoading = !state.areUserReportsHistoryLoading
    },
    BAD_REQUEST: (state, requestStatus) => {
        state.request.error = {status: requestStatus[1], message: requestStatus[0]}
    },
    SHOW_USER_REPORTS: (state, value) => {
        state.showUserReports = value;
    }
};

const actions = {
    setUserReports: async (store, params) => {
        store.commit('SET_USER_REPORTS_LOADING');
        await api.userReport.getUserReports(params)
            .then(response => {
                store.commit('SET_USER_REPORTS', response.data.data.userReports);
                store.commit('SET_USER_REPORTS_LOADING');
            })
            .catch(error => {
                const requestStatus = [error.message, error.error_code];
                store.commit('BAD_REQUEST', requestStatus);
                store.commit('SET_USER_REPORTS_LOADING');
            });
    },
    setUserReportsHistory: async (store, params) => {
        store.commit('SET_USER_REPORTS_HISTORY_LOADING');
        await api.userReport.getUserReportsHistory(params)
            .then(response => {
                store.commit('SET_USER_REPORTS_HISTORY', response.data.data.userReports);
                store.commit('SET_USER_REPORTS_HISTORY_LOADING');
            })
            .catch(error => {
                const requestStatus = [error.message, error.error_code];
                store.commit('BAD_REQUEST', requestStatus);
                store.commit('SET_USER_REPORTS_HISTORY_LOADING');
            });
    },
    setShowUserReports: (store, value) => {
        store.commit('SHOW_USER_REPORTS', value)
    }
};

const getters = {
    userReports: state => {
        return state.userReports;
    },
    areUserReportsLoading: state => {
        return state.areUserReportsLoading;
    },
    userReportsHistory: state => {
        return state.userReportsHistory;
    },
    areUserReportsHistoryLoading: state => {
        return state.areUserReportsHistoryLoading;
    },
    showUserReports: state => {
        return state.showUserReports;
    },
    userReportsCount: state => {
        return state.userReports.length;
    }
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
