<template>
    <svg xmlns="http://www.w3.org/2000/svg"
         :width="width"
         :height="height"
         :viewBox="viewBox"
         :aria-labelledby="iconName"
         :id="idName"
         :class="className"
         role="presentation"
    >
        <title :id="iconName">
            {{iconName}}
        </title>
        <g :fill="iconColor" :stroke="strokeColor">
            <slot/>
        </g>
    </svg>
</template>

<script>
export default {
    props: {
        iconName: {
            type: String,
            default: 'box'
        },
        width: {
            type: [Number, String],
            default: 18
        },
        height: {
            type: [Number, String],
            default: 18
        },
        iconColor: {
            type: String,
            default: 'currentColor'
        },
        strokeColor: {
            type: String,
            default: 'transparent'
        },
        viewBox: {
            type: String,
            default: '0 0 50 50'
        },
        className: {
            type: String,
            default: ''
        },
        idName: {
            type: String,
            default: ''
        }
    }
}
</script>