import api from '@/services/api/index.js'

const state = {
    actualRisks: {},
    riskEvolution: {},
    actualRisksLoading: false,
    evolutionRiskLoading: false,
    requestActual: {},
    requestEvolution: {},
    requestHistory: {},
    requestGlobal: {},
    globalActualRisks: {
        current: [],
        history: []
    },
    maxRiskBySensor: [],
    globalActualRisksLoading: false,
    globalRisksHistoryLoading:false,
    selectedRollingRisk: {}
};


const mutations = {
    SET_ACTUAL_RISKS: (state, risks) => {
        state.actualRisks = risks;
    },
    SET_RISK_EVOLUTION: (state, risks) => {
        state.riskEvolution = risks;
    },
    SET_GLOBAL_ACTUAL_RISKS: (state, risks) => {
        state.globalActualRisks.current = risks;
    },
    SET_MAX_RISKS: (state, risks) => {
        state.maxRiskBySensor = risks;
    },
    SET_GLOBAL_RISKS_HISTORY: (state, risks) => {
        state.globalActualRisks.history = risks;
    },
    SET_SELECTED_ROLLING_RISK: (state, rollingRisk) => {
        state.selectedRollingRisk = rollingRisk;
    },
    SET_ACTUAL_RISKS_LOADING: (state) => {
        state.actualRisksLoading = !state.actualRisksLoading
    },
    SET_GLOBAL_ACTUAL_RISKS_LOADING: (state) => {
        state.globalActualRisksLoading = !state.globalActualRisksLoading
    },
    SET_GLOBAL_RISKS_HISTORY_LOADING: (state) => {
        state.globalRisksHistoryLoading = !state.globalRisksHistoryLoading
    },
    SET_EVOLUTION_RISK_LOADING: (state) => {
        state.evolutionRiskLoading = !state.evolutionRiskLoading
    },
    SET_REQUEST_RESPONSE_ACTUAL: (state, response) => {
        state.requestActual = {
            message: response.data.message,
            status: response.status,
            success: response.data.success
        }
    },
    SET_REQUEST_RESPONSE_HISTORY: (state, response) => {
        state.requestHistory = {
            message: response.data.message,
            status: response.status,
            success: response.data.success
        }
    },
    SET_REQUEST_RESPONSE_GLOBAL: (state, response) => {
        state.requestGlobal = {
            message: response.data.message,
            status: response.status,
            success: response.data.success
        }
    },
    SET_REQUEST_RESPONSE_EVOLUTION: (state, response) => {
        state.requestEvolution = {
            message: response.data.message,
            status: response.status,
            success: response.data.success
        }
    },
    SET_REQUEST_ERROR_ACTUAL: (state, error) => {
        state.requestActual = {
            message: error.response.data.message ?? error.response.statusText,
            status: error.response.data.error_code ?? error.response.status,
            success: error.response.data.success
        }
    },
    SET_REQUEST_ERROR_EVOLUTION: (state, error) => {
        state.requestEvolution = {
            message: error.response.data.message ?? error.response.statusText,
            status: error.response.data.error_code ?? error.response.status,
            success: error.response.data.success
        }
    },
    SET_REQUEST_ERROR_GLOBAL: (state, error) => {
        state.requestGlobal = {
            message: error.response.data.message ?? error.response.statusText,
            status: error.response.data.error_code ?? error.response.status,
            success: error.response.data.success
        }
    },
    SET_REQUEST_ERROR_HISTORY: (state, error) => {
        state.requestHistory = {
            message: error.response.data.message ?? error.response.statusText,
            status: error.response.data.error_code ?? error.response.status,
            success: error.response.data.success
        }
    },
};

const actions = {
    setActualRollingRisksBySensor: async (store, sensor) => {
        store.commit('SET_ACTUAL_RISKS_LOADING');
        await api.sensor.getActualRollingRisksBySensor(sensor)
            .then(response => {
                if (response.status !== 204) {
                    store.commit('SET_ACTUAL_RISKS', response.data.data.rollingRisks);
                }
                store.commit('SET_REQUEST_RESPONSE_ACTUAL', response);
                store.commit('SET_ACTUAL_RISKS_LOADING');
            })
            .catch(error => {
                store.commit('SET_REQUEST_ERROR_ACTUAL', error);
                store.commit('SET_ACTUAL_RISKS_LOADING');
            });
    },
    setGlobalActualRollingRisks: async (store, params) => {
        store.commit('SET_GLOBAL_ACTUAL_RISKS_LOADING');
        await api.rollingRisk.getRollingRisksByCriteria(params)
            .then(response => {
                store.commit('SET_REQUEST_RESPONSE_GLOBAL', response);
                store.commit('SET_MAX_RISKS', response.data.data.maxRiskBySensor);
                store.commit('SET_GLOBAL_ACTUAL_RISKS', response.data.data.rollingRisks);
                store.commit('SET_GLOBAL_ACTUAL_RISKS_LOADING');
            })
            .catch(error => {
                store.commit('SET_REQUEST_ERROR_GLOBAL', error);
                store.commit('SET_ACTUAL_RISKS_LOADING');
            });
    },
    setGlobalRollingRisksHistory: async (store, params) => {
        store.commit('SET_GLOBAL_RISKS_HISTORY_LOADING');
        await api.rollingRisk.getRollingRiskHistoryByCriteria(params)
            .then(response => {
                store.commit('SET_REQUEST_RESPONSE_HISTORY', response);
                store.commit('SET_GLOBAL_RISKS_HISTORY', response.data.data.rollingRisks);
                store.commit('SET_GLOBAL_RISKS_HISTORY_LOADING');
            })
            .catch(error => {
                store.commit('SET_REQUEST_ERROR_HISTORY', error);
                store.commit('SET_GLOBAL_RISKS_HISTORY_LOADING');
            });
    },
    setRollingRisksEvolutionBySensor: async (store, data) => {
        store.commit('SET_EVOLUTION_RISK_LOADING');
        await api.sensor.getRollingRiskEvolutionBySensor(data.sensor, {params: data.params})
            .then(response => {
                store.commit('SET_REQUEST_RESPONSE_EVOLUTION', response);
                store.commit('SET_RISK_EVOLUTION', response.data.data.rollingRisks);
                store.commit('SET_EVOLUTION_RISK_LOADING');
            })
            .catch(error => {
                store.commit('SET_REQUEST_ERROR_EVOLUTION', error);
                store.commit('SET_EVOLUTION_RISK_LOADING');
            });
    },
    setSelectedRollingRisk: (store, rollingRisk) => {
        store.commit('SET_SELECTED_ROLLING_RISK', rollingRisk)
    }
};

const getters = {
    actualRisks: state => {
        return state.actualRisks
    },
    globalActualRisks: state => {
        return state.globalActualRisks.current
    },
    globalRisksHistory: state => {
        return state.globalActualRisks.history
    },
    hasPollenHistoryData: state => {
        return state.globalActualRisks.history.length > 0
    },
    selectedRollingRisk: state => {
        return state.selectedRollingRisk
    },
    hasPollenData: state => {
        return state.globalActualRisks.current.length > 0
    },
    hasPollenBeenDetected: state => {
        let pollens = state.globalActualRisks.current.filter(item => item.riskLevel !== null)
        return pollens.length > 0
    },
    getRiskByCategory: (state) => (category) => {
        return state.globalActualRisks.current.filter(item => item.category === category)
    },
    getDetectedRiskByCategory: (state) => (category) => {
        const detectedRisks = state.globalActualRisks.current.filter(item => item.riskLevel !== null);
        detectedRisks.sort((a, b) => b.riskLevel - a.riskLevel);
        return detectedRisks.filter(item => item.category === category)
    },
    getDetectedGlobalRisk: (state) => {
        const detectedRisks = state.globalActualRisks.current.filter(item => item.riskLevel !== null);
        detectedRisks.sort((a, b) => b.riskLevel - a.riskLevel);
        return detectedRisks
    },
    riskEvolution: state => {
        return state.riskEvolution
    },
    actualRisksLoading: state => {
        return state.actualRisksLoading
    },
    globalActualRisksLoading: state => {
        return state.globalActualRisksLoading
    },
    globalRisksHistoryLoading: state => {
        return state.globalRisksHistoryLoading
    },
    evolutionRiskLoading: state => {
        return state.evolutionRiskLoading
    },
    getRequestActual: state => {
        return state.requestActual
    },
    getRequestEvolution: state => {
        return state.requestEvolution
    },
    getRequestGlobal: state => {
        return state.requestGlobal
    },
    getRequestHistory: state => {
        return state.requestHistory
    },
    getMaxRiskBySensor: state => {
        return state.maxRiskBySensor;
    }
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}