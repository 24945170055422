<template>
    <div class="evolution-chart">
        <BarChart :chart-options="options"
                  :height="'200px'"
                  :series="chartData"
                  :handle-click="'selectDate'"
                  @selectDate="selectDate"
                  class="evolution-chart"
                  :class="isWidget ? 'widget' : ''"/>
    </div>
</template>

<script>
import BarChart from "@/components/charts/BarChart";
import moment from 'moment';
import {apexchartsOptions} from "@/services/charts/apexchartsOptions";
import Vuex from "vuex";
import SecureLS from "@/plugins/secureLS";

export default {
    name: "RollingRiskEvolutionBarChart",
    components: {
        BarChart
    },
    data() {
        return {
            series: [{
                name: this.$options.filters.capitalize(this.$t('risk.this')),
                data: []
            }],
            options: apexchartsOptions.riskEvolutionBar,
            isDataComplete: true,
        }
    },
    props: {
        risks: Array,
        isWidget: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...Vuex.mapGetters('filters', {
            filters: 'filters',
        }),
        chartData() {
            return this.series;
        },
        isAuthorized() {
            let currentUser = SecureLS.get('currentUser');
            return currentUser.roles.includes('ROLE_ADMIN') || currentUser.roles.includes('ROLE_SUPER_ADMIN')
        }
    },
    methods: {
        ...Vuex.mapActions('filters', {
            setFilters: 'setFilters',
        }),
        selectDate(dataIndex) {
            this.setFilters({date: this.chartData[0].data[dataIndex].x, fromMap: false})
        },
        setChartData() {
            let values = [];
            let riskEvolution = [...this.risks];

            let now = moment().subtract(1, 'days');
            let twoWeeksAgo = moment();
            twoWeeksAgo = twoWeeksAgo.subtract(15, 'days');
            let twoWeeksDates = [];

            if (this.filters.date) {
                now = moment(this.filters.date);
                twoWeeksAgo = moment(this.filters.date);
                twoWeeksAgo = twoWeeksAgo.subtract(15, 'days');
            }

            while (twoWeeksAgo.add(1, 'days').diff(now) <= 0) {
                twoWeeksDates.push(twoWeeksAgo.format('YYYY-MM-DD'));
            }

            twoWeeksDates.forEach(date => {
                let report =
                    riskEvolution.find(item => moment(item.day, 'YYYY-MM-DD').format('YYYY-MM-DD') === date)
                let riskLevel = null;

                if (report !== undefined) {
                    if (report.riskLevel === null) {
                        riskLevel = 0.2
                    } else if (report.riskLevel === 0) {
                        riskLevel = 0.4
                    } else if (parseInt(report.riskLevel) > 0) {
                        riskLevel = parseInt(report.riskLevel)
                    }
                } else {
                    this.isDataComplete = false;
                    riskLevel = null;
                }

                values.push({x: date, y: riskLevel})
            })

            this.series = [{
                name: this.$options.filters.capitalize(this.$t('risk.this')),
                data: values
            }];
            this.updateIsComplete();
        },
        updateIsComplete() {
            this.$emit('updateIsComplete', this.isDataComplete)
        },
    },
    mounted() {
        this.setChartData();
    },
}
</script>

<style lang="scss">
.evolution-chart {
    width: 90%;
    height: 200px;

    @media only screen and (max-width: 600px) {
        width: 100% !important;
    }

    &.widget {
        height: 80%;
    }
}
</style>
