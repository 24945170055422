import api from '@/services/api/index.js'
import SecureLS from '@/plugins/secureLS.js'
import router from "../../router";
import {parseJWT} from "@/services/api/JWTParser";
import Toast from "@/services/swal2/mixins";
import i18n from "@/i18n";

const token = SecureLS.get(btoa('token'));
const state = token
    ? {
        status: {loggedIn: true},
        token,
        request: {},
        askResetPasswordLinkIsLoading: false,
        resetPasswordMailSent: false,
        isResetTokenValid: false,
        connexionIsLoading: false,
        api_key: '',
    }
    : {
        status: {},
        token: null,
        request: {},
        askResetPasswordLinkIsLoading: false,
        resetPasswordMailSent: false,
        isResetTokenValid: false,
        connexionIsLoading: false,
        api_key: '',
    };


const mutations = {
    AUTHENTICATE: (state, token) => {
        state.status = {loggedIn: true};
        state.token = token;
        state.request = {};
    },
    LOGOUT: (state) => {
        state.status = {loggedIn: false};
        state.request = {};
        state.token = null;
    },
    CONNEXION_IS_LOADING_STATUS: (state) => {
        state.connexionIsLoading = !state.connexionIsLoading
    },
    BAD_AUTHENTICATE: (state, requestStatus) => {
        state.status = {loggedIn: false};
        state.token = null;
        state.request = requestStatus[0]
    },
    RESET_PASSWORD_MAIL: (state) => {
        state.resetPasswordMailSent = true;
    },
    VALIDATE_TOKEN: (state, isValid) => {
        state.isResetTokenValid = isValid;
    },
    VALIDATE_RESET: (state) => {
        state.isResetSuccess = true;
    },
    API_KEY: (state, key) => {
        state.api_key = key;
    }
};
const actions = {
    logMeIn: async (store, authenticate) => {
        store.commit('CONNEXION_IS_LOADING_STATUS');
        await api.authentication.authenticate(authenticate[0], authenticate[1])
            .then(response => {
                if (response.data) {
                    let JWTToken = response.data.token
                    let parsedToken = parseJWT(response.data.token)

                    let tokenDate = new Date(parsedToken.exp * 1000)
                    let timestampedToken = {
                        token: JWTToken,
                        timestamp: tokenDate
                    }

                    let isAdmin = parsedToken.roles.includes('ROLE_ADMIN') || parsedToken.roles.includes('ROLE_SUPER_ADMIN') || parsedToken.roles.includes('ROLE_LIFYAIR_COLLABORATOR');
                    if (parsedToken.roles.includes('ROLE_API') && !isAdmin) {
                        Toast.fire({

                            icon: 'error',

                            title: i18n.t('common.toast.request.accessDenied'),

                        })
                        return;
                    }

                    store.commit('AUTHENTICATE', timestampedToken);
                    SecureLS.set(btoa('token'), timestampedToken);

                    if (parsedToken.api_key) {
                        SecureLS.set('currentUser', {
                            email: parsedToken.email,
                            roles: parsedToken.roles,
                            apiKey: parsedToken.api_key[0]
                        });
                    } else {
                        SecureLS.set('currentUser', {email: parsedToken.email, roles: parsedToken.roles});
                    }
                    Toast.fire({
                        icon: 'success',
                        title: i18n.t('common.toast.welcome'),
                        timer: 1500
                    }).then(() => {
                        router.push('/')
                        store.commit('CONNEXION_IS_LOADING_STATUS');
                    })

                } else {
                    store.commit('CONNEXION_IS_LOADING_STATUS');
                    const requestStatus = [response.data.message.message];
                    store.commit('BAD_AUTHENTICATE', requestStatus);
                }


            })
            .catch(error => {
                store.commit('CONNEXION_IS_LOADING_STATUS');
                const requestStatus = [error.response];
                store.commit('BAD_AUTHENTICATE', requestStatus);
            });
    },
    logout: (store) => {
        store.commit('LOGOUT')
        SecureLS.removeAll()
        setTimeout(() => {
            router.push('/login')
        }, 1000)
    },
    askForPasswordReset: async (store, data) => {
        await api.authentication.resetPassword(data)
            .then(() => {
                store.commit('RESET_PASSWORD_MAIL')
                Toast.fire({
                    icon: 'success',
                    title: i18n.t('common.toast.resetPassword.askSuccess'),
                    timer: 1500
                }).then(() => {
                    setTimeout(() => {
                        router.push('reset-password')
                    }, 1000)
                })
            })
            .catch(error => {
                store.commit('CONNEXION_IS_LOADING_STATUS');
                //let response = error.response.data;
                const requestStatus = [error.response.data];
                store.commit('BAD_AUTHENTICATE', requestStatus);
            });
    },
    confirmToken: async (store, data) => {
        await api.authentication.confirmResetPasswordToken(data)
            .then(() => {
                store.commit('VALIDATE_TOKEN', true)
                Toast.fire({
                    icon: 'success',
                    title: i18n.t('common.toast.resetPassword.correctToken'),
                })
            })
            .catch(() => {
                store.commit('VALIDATE_TOKEN', false);
                Toast.fire({
                    icon: 'error',
                    title: i18n.t('common.toast.resetPassword.incorrectToken'),
                })
            });
    },
    resetPassword: async (store, data) => {
        await api.authentication.sendNewPassword(data)
            .then(() => {
                Toast.fire({
                    icon: 'success',
                    timer: 1500,
                    title: i18n.t('common.toast.resetPassword.success'),
                }).then(() => {
                        setTimeout(() => {
                            store.commit('VALIDATE_TOKEN', false);
                            router.push({path: '/login'})
                        }, 1000);
                    }
                )
            })
            .catch(error => {
                store.commit('CONNEXION_IS_LOADING_STATUS');
                //let response = error.response.data;
                const requestStatus = [error.response.data];
                store.commit('BAD_AUTHENTICATE', requestStatus);
                Toast.fire({
                    icon: 'error',
                    title: i18n.t('common.toast.resetPassword.error'),
                })
            });
    },
    checkHealth: async () => {
        await api.authentication.healthCheck()
            .then(() => {
                console.log('Health API OK')
            })
            .catch(() => {
                router.push({name: 'error', params: {type: 'maintenance'}});
            })
    }
};

const getters = {
    authStatus: state => {
        return state.request
    },
    getAskResetPasswordLinkIsLoading: state => {
        return state.askResetPasswordLinkIsLoading
    },
    resetMailSent: state => {
        return state.resetPasswordMailSent
    },
    getConnexionIsLoading: state => {
        return state.connexionIsLoading
    },
    getIsResetTokenValid: state => {
        return state.isResetTokenValid;
    },
    getApiKey: state => {
        return state.api_key;
    }
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
