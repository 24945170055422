import axios from "../../plugins/axios";
import authHeader from "./authHeader";

export default {
    getNotificationSettingsCount
}

function getNotificationSettingsCount(params) {
    return axios.get('/map/notification-settings', {...authHeader.classicalHeader(), ...params})
}


