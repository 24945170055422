<template>
    <p class="clr-lifyair add-cursor" @click="downloadPdf()">
        {{ btnTxt }}
        <b-progress v-if="showProgress" :value="progressValue" :max="100" show-progress animated></b-progress>
    </p>
</template>

<script>
import Docxtemplater from 'docxtemplater';
import JSZip from 'jszip';
import JSzipUtils from "jszip-utils";
import saveAs from 'file-saver';
import Vuex from 'vuex';
import SecureLS from '@/plugins/secureLS.js'

export default {
    name: "DownloadButton",
    props: {
        btnTxt: String,
        type: String
    },
    data() {
        return {
            documentationType: null,
            templateType: [
                'api_template.docx',
                'widget_template.docx'
            ],
            apiData: {
                apiKey: 'fillApiKey',
            },
            widgetData: {
                horizontalWidget: "",
                verticalWidget: "",
            },
            progressValue: 0,
            showProgress: false,
            apiKey: 'test',
        }
    },
    computed: {
        ...Vuex.mapGetters('login', {
            getApiKey: 'getApiKey'
        })
    },
    methods: {
        loadFile(url, options) {
            JSzipUtils.getBinaryContent(url, options);
        },
        downloadPdf() {
            let chosenType = this.documentationType;
            this.showProgress = true;
            this.apiData.apiKey = this.apiKey;
            this.widgetData.horizontalWidget = "<div><iframe src='https://app.live-pollen.com/widget/home?apiKey=" + this.apiKey + "&amp;isBanner=true' style='min-height: 200px; border: 0px; width: 100%'></iframe></div>"
            this.widgetData.verticalWidget = "<div><iframe src='https://app.live-pollen.com/widget/home?apiKey=" + this.apiKey + "' style='min-height: 380px; border: 0px; width: 100%'></iframe></div>"
            this.loadFile('https://lifyair-webapp-client-file.s3.eu-west-1.amazonaws.com/' + chosenType.toLowerCase() + '_template.docx', {
                callback: (error, content) => {
                    if (error) {
                        throw error
                    }
                    let zip = new JSZip(content);
                    let doc = new Docxtemplater().loadZip(zip)

                    let data = null
                    chosenType === 'API' ? data = this.apiData : data = this.widgetData;
                    doc.setData(data)
                    try {
                        doc.render()
                    } catch (error) {
                        let e = {
                            message: error.message,
                            name: error.name,
                            stack: error.stack,
                            properties: error.properties,
                        }
                        console.log(JSON.stringify({error: e}));

                        throw error;
                    }

                    let finalDocument = doc.getZip().generate({
                        type: "blob",
                        mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    })
                    // Download File on Computer
                    saveAs(finalDocument, `Documentation_` + chosenType + `.docx`);
                },
                progress: (e) => {
                    this.progressValue = Math.round(e.percent * 100) / 100;
                },
            });
        }
    },
    mounted() {
        this.documentationType = this.type;
    },
    created() {
        let currentUser = SecureLS.get('currentUser')
        this.apiKey = currentUser.apiKey;
    },
    watch: {
        progressValue: async function (val) {
            if (val === 100) {
                setTimeout(() => {
                    this.showProgress = false
                }, 10000);
            }
        }
    }
}
</script>

<style scoped>

</style>
