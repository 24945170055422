import axios from "../../plugins/axios";
import authHeader from "./authHeader";

export default {
    getSensorOptions,
    getAccountOptions,
    getPollenOptions,
    getGlobalAnalytics,
    getAccountAnalytics,
    getAccountUsersAnalytics
}

let prefix = '/analytics'

function getSensorOptions(data = null) {
    return axios.get(prefix + '/sensors', {...authHeader.classicalHeader(),...data})
}
function getAccountOptions() {
    return axios.get(prefix + '/accounts', authHeader.classicalHeader())
}
function getPollenOptions() {
    return axios.get('/report-types', authHeader.classicalHeader())
}
function getGlobalAnalytics(data = null) {
    return axios.get(prefix + '/data/' + data[0], {...authHeader.classicalHeader(),...data[1]})
}

function getAccountAnalytics(data){
    return axios.get(prefix + '/data-client?', { ...authHeader.classicalHeader(), params:data});
}

function getAccountUsersAnalytics(data) {
    let formData = new FormData()
    const cloneData = { ...data };
    if(cloneData.clusters !== undefined){
        for (let i = 0; i < cloneData.clusters.length; i++) {
            formData.append('clusters[]', JSON.stringify(cloneData.clusters[i]))
        }
    }
    delete cloneData.clusters
    return axios.post(prefix + '/data-client-analytics', formData, {...authHeader.formDataHeader(), params:cloneData});
}