import authentication from './authentication.js'
import sensor from "./sensor";
import report from "./report";
import risk from "./risk";
import rollingRisk from "./rollingRisk";
import account from "@/services/api/account";
import userReport from "@/services/api/userReport";
import globalConcentration from "@/services/api/globalConcentration";
import analytics from "@/services/api/analytics";
import reportType from "@/services/api/reportType";
import notificationSettings from "@/services/api/notificationSettings";
import sensorAnalytic from "@/services/api/sensorAnalytic";

export default {
	authentication,
	sensor,
	report,
	risk,
	account,
	rollingRisk,
	userReport,
	globalConcentration,
	analytics,
	reportType,
	notificationSettings,
	sensorAnalytic
}
