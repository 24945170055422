import Vue from 'vue'
import Router from 'vue-router'
import secureLS from '../plugins/secureLS.js'
import store from '../store/index';
import moment from "moment";
import i18n from "@/i18n";

Vue.use(Router);

const router = new Router({
    routes: [
        {
            path: '/',
            name: 'dashboard',
            component: () => import(/* webpackChunkName: "about" */ '@/views/Dashboard.vue'),
            meta: {requiresAuth: true}
        },
        {
            path: '/analytics',
            name: 'analytics',
            component: () => import(/* webpackChunkName: "about" */ '@/views/Analytics'),
            meta: {requiresAuth: true}
        },
        {
            path: '/login',
            name: 'login',
            meta: {layout: "NoSidebar", requiresAuth: false},
            component: () => import(/* webpackChunkName: "about" */ '@/views/Login.vue'),
        },
        {
            path: '/reset-password',
            name: 'reset-password',
            meta: {layout: "NoSidebar", requiresAuth: false},
            component: () => import(/* webpackChunkName: "about" */ '@/views/ResetPassword.vue'),
        },
        {
            path: '/widget/home',
            name: 'widgetHome',
            meta: {layout: "NoSidebar", requiresAuth: false},
            component: () => import(/* webpackChunkName: "about" */ '@/components/widget/WidgetHome.vue'),
        },
        {
            path: '*',
            name: 'error',
            props: true,
            meta: {requiresAuth: false},
            component: () => import(/* webpackChunkName: "about" */ '@/views/Error.vue'),
        },

    ],
    // eslint-disable-next-line
    scrollBehavior: (to, from, savedPosition) => {
        if (to.hash) {
            return {selector: to.hash}
        } else {
            return {x: 0, y: 0}
        }
    },
    mode: 'history',
});

router.beforeEach((to, from, next) => {
    const publicPages = ['/login', '/reset-password', '/widget/home', '/error'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = secureLS.get(btoa('token'));
    const appVersion = secureLS.get('app_version');

    if ((appVersion && appVersion !== store.getters.appVersion) || !appVersion) {
        secureLS.set('app_version', store.getters.appVersion)
    }
    window.document.title = to.meta && to.meta.title ? to.meta.title : i18n.t('common.title.project');

    if (to.name !== 'error') {
        if (authRequired) {
            if (!loggedIn.token) {
                return next('/login');
            } else if (moment().isAfter(moment(loggedIn.timestamp))) {
                secureLS.remove(btoa('token'))
                return next('/login');
            }
        } else if (to.name === 'login' && loggedIn.token) {
            return next('/');
        } else {
            next();
        }
    }
    next();
});

export default router;
