import api from '@/services/api/index.js'

const state = {
    risks: {
        actual: {},
        evolution: {},
    },
    globalRisks: {
        actual: {},
        evolution: {},
    },
    individualRisk: [],
    zoneRisk: {},
    loadingStatus: false,
    request: {
        data: {},
        error: {}
    }
};


const mutations = {
    SET_ACTUAL_RISKS: (state, risks) => {
        state.loadingStatus = true
        state.risks.actual = risks;
        state.loadingStatus = false
    },
    SET_EVOLUTION_RISKS: (state, risks) => {
        state.loadingStatus = true
        state.risks.evolution = risks;
        state.loadingStatus = false
    },
    SET_GLOBAL_EVOLUTION_RISKS: (state, risks) => {
        state.loadingStatus = true
        state.globalRisks.evolution = risks;
        state.loadingStatus = false
    },
    SET_INDIVIDUAL_RISK: (state, risk) => {
        state.loadingStatus = true
        state.individualRisk.push(risk);
        state.loadingStatus = false
    },
    SET_ZONE_RISKS: (state, risk) => {
        state.loadingStatus = true
        state.zoneRisk = risk;
        state.loadingStatus = false
    },
    BAD_REQUEST: (state, requestStatus) => {
        state.request.error = {status: requestStatus[1], message: requestStatus[0]}
    },
    RISKS_IS_LOADING_STATUS: (state) => {
        state.loadingStatus = !state.loadingStatus
    },
    CLEAR_RISK: (state) => {
        state.risks.evolution = {}
    },
    CLEAR_GLOBAL_RISK: (state) => {
        state.globalRisks.evolution = {}
    },
};
const actions = {
    setRisks: async (store, data) => {
        await api.risk.getRisks(data)
            .then(response => {
                if (data.sensorId) {
                    if (data.period === 'day') {
                        store.commit('SET_ACTUAL_RISKS', response.data.data);
                    } else {
                        store.commit('SET_EVOLUTION_RISKS', response.data.data);
                    }
                } else {
                    store.commit('SET_GLOBAL_EVOLUTION_RISKS', response.data.data);
                }
            })
            .catch(error => {
                const requestStatus = [error.message, error.error_code];
                store.commit('BAD_REQUEST', requestStatus);
            });
    },
    setGlobalRisks: async (store, data) => {
        await api.risk.getRisks(data)
            .then(response => {
                store.commit('SET_GLOBAL_EVOLUTION_RISKS', response.data.data);
            })
            .catch(error => {
                const requestStatus = [error.message, error.error_code];
                store.commit('BAD_REQUEST', requestStatus);
            });
    },
    setIndividualRisk: async (store, data) => {
        await api.risk.getRisks(data)
            .then(response => {
                store.commit('SET_INDIVIDUAL_RISK', response.data.data);
            })
            .catch(error => {
                const requestStatus = [error.message, error.error_code];
                store.commit('BAD_REQUEST', requestStatus);
            });
    },
    setZoneRisks: async (store, zone) => {
        await api.risk.getRisks(zone)
            .then(response => {
                store.commit('SET_ZONE_RISKS', response.data.data);
            })
            .catch(error => {
                const requestStatus = [error.message, error.error_code];
                store.commit('BAD_REQUEST', requestStatus);
            });
    },
    clearRisks: (store) => {
        store.commit('CLEAR_RISK')
    },
    clearGlobalRisks: (store) => {
        store.commit('CLEAR_GLOBAL_RISK')
    }
};

const getters = {
    risks: state => {
        return state.risks
    },
    globalRisks: state => {
        return state.globalRisks
    },
    individualRisk: state => {
        return state.individualRisk
    },
    getIndividualRisk: (state) => (id) => {
        return state.individualRisk.find(risk => risk.id[0] === id)
    },
    getZoneRisk: (state) => (pollen) => {
        return Object.keys(state.zoneRisk).length > 0 ?
            state.zoneRisk.find(zone => zone.reportType.name === pollen) :
            state.zoneRisk;
    },
    getRisksById: (state) => (id) => {
        return state.risks.find(report => report.id === id)
    },
    loadingStatus: state => {
        return state.loadingStatus
    },
    getRequest: state => {
        return state.request
    },
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
