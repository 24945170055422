<template>
    <b-form inline class="filter-control"
            @submit.prevent="handleFilters">
        <b-col cols="12" class="d-md-none mb-2 text-center">
            <b-icon icon="x-circle-fill"
                    class="close-control"
                    variant="primary"
                    @click="hideFilters()"/>
        </b-col>
        <!-- ******** ACCOUNTS ******** -->
        <div v-if="isEnable" :class="inputClasses">
            <multiselect
                v-model="newFiltersData.accounts"
                :disabled="newFiltersData.showUserReports"
                :options="orderedAccounts"
                :class="fieldStatus('accounts')"
                class="account-select"
                :multiple="true"
                :close-on-select="false"
                @input="updateFormStatus()"
                :show-labels="false"
                :group-select="true"
                group-values="accountGroups"
                group-label="name"
                :placeholder="$t('form.field.accountsChoice') | capitalize"
                track-by="name"
                label="name">
                <template slot="option" slot-scope="props">
                    <span v-if="props.option.$isLabel">{{ props.option.$groupLabel }}</span>
                    <span class="pl-2" v-else>{{ props.option.name }}</span>
                </template>
                <template slot="noOptions">
                    {{ $t('form.helper.noResult') | capitalize }}
                </template>
                <template slot="selection" slot-scope="{ values, isOpen }">
                    <span class="multiselect__single"
                          v-if="values.length && !isOpen">
                    <IconBase view-box="0 0 16 12" width="15px" height="15px" class="mr-1"
                              v-if="fieldStatus('accounts') === 'applied'">
                        <IconCheck/>
                    </IconBase>
                        <span v-if="values.length > 1">
                            {{ values.length }} {{ $t('form.placeholder.selectedAccounts') }}
                        </span>
                        <span v-else>
                            {{ values[0].name |capitalize }}
                        </span>
                    </span>
                </template>
            </multiselect>
        </div>

        <!-- ******** VISIBILITY ******** -->
        <div v-if="isEnable" :class="inputClasses">
            <multiselect
                v-model="newFiltersData.visibility"
                :options="visibilityOptions"
                :disabled="newFiltersData.showUserReports"
                :searchable="false"
                :close-on-select="true"
                :class="fieldStatus('visibility')"
                @input="updateFormStatus()"
                :show-labels="false"
                label="visbility"
                :placeholder="$t('form.field.status') | capitalize">
                <template slot="option" slot-scope="{option}">
                    {{ $t(`common.${option}`) | capitalize }}
                </template>
                <template slot="singleLabel" slot-scope="{ option }">
                    <IconBase view-box="0 0 16 12" width="15px" height="15px" class="mr-1"
                              v-if="fieldStatus('visibility') === 'applied'">
                        <IconCheck/>
                    </IconBase>
                    {{ $t(`common.${option}`) | capitalize }}
                </template>
            </multiselect>
        </div>

        <!-- ******** SENSORS ******** -->
        <div :class="inputClasses">
            <multiselect
                v-model="newFiltersData.sensors"
                :options="orderedSensors"
                :class="fieldStatus('sensors')"
                :multiple="isEnable"
                :disabled="newFiltersData.showUserReports"
                :close-on-select="!isEnable"
                :placeholder="$tc('form.field.sensorsChoice', isEnable ? 2 : 1) | capitalize"
                @input="updateFormStatus()"
                :show-labels="false"
                track-by="serialNumber"
                label="sensorName">
                <template slot="noOptions">
                    {{ $t('form.helper.noResult') | capitalize }}
                </template>
                <template slot="selection" slot-scope="{ values, isOpen }">
                    <span class="multiselect__single"
                          v-if="values.length && !isOpen">
                    <IconBase view-box="0 0 16 12" width="15px" height="15px" class="mr-1"
                              v-if="fieldStatus('sensors') === 'applied'">
                        <IconCheck/>
                    </IconBase>
                        <span>
                            {{ values.length }} {{ $tc('form.placeholder.selectedSensors', values.length > 1 ? 1 : 2) }}
                        </span>
                    </span>
                </template>
            </multiselect>
        </div>

        <!-- ******** POLLENS ******** -->
        <div :class="inputClasses">
            <multiselect
                v-model="newFiltersData.pollens"
                :disabled="newFiltersData.showUserReports && !isEnable"
                :options="orderedPollens"
                :class="fieldStatus('pollens')"
                :multiple="true"
                :close-on-select="false"
                :placeholder="$t('form.field.pollensChoice') | capitalize"
                @input="updateFormStatus()"
                :show-labels="false"
                track-by="translatedName"
                label="translatedName">
                <template slot="option" slot-scope="{option}">
                    <span class="option__title">
                        {{ $t(`pollen.type.${option.name}`) | capitalize }}
                    </span>
                </template>
                <template slot="noOptions">
                    {{ $t('form.helper.noResult') | capitalize }}
                </template>
                <template slot="selection" slot-scope="{ values, isOpen }">
                    <span class="multiselect__single"
                          v-if="values.length && !isOpen">
                    <IconBase view-box="0 0 16 12" width="15px" height="15px" class="mr-1"
                              v-if="fieldStatus('pollens') === 'applied'">
                        <IconCheck/>
                    </IconBase>
                        <span v-if="values.length > 1">
                            {{ values.length }} {{ $t('form.placeholder.selectedPollens') }}
                        </span>
                        <span v-else>
                            {{ $t(`pollen.type.${values[0].name}`) | capitalize }}
                        </span>
                    </span>
                </template>
            </multiselect>
        </div>

        <!-- ******** RISK LEVELS ******** -->
        <div v-if="isEnable" :class="inputClasses">
            <multiselect
                v-model="newFiltersData.riskLevels"
                :options="riskLevelOptions"
                :disabled="newFiltersData.showUserReports"
                :class="fieldStatus('riskLevels')"
                :multiple="true"
                :close-on-select="false"
                :placeholder="$t('risk.riskLevel') | capitalize"
                @input="updateFormStatus()"
                :show-labels="false"
                :searchable="false">
                <template slot="option" slot-scope="{option}">
                    <span class="option__title">{{ $t(`risk.${option}`) | capitalize }}</span>
                </template>
                <template slot="noOptions">
                    {{ $t('form.helper.noResult') | capitalize }}
                </template>
                <template slot="selection" slot-scope="{ values, isOpen }">
                    <span class="multiselect__single"
                          v-if="values.length && !isOpen">
                    <IconBase view-box="0 0 16 12" width="15px" height="15px" class="mr-1"
                              v-if="fieldStatus('riskLevels') === 'applied'">
                        <IconCheck/>
                    </IconBase>
                        <span v-if="values.length > 1">
                            {{ values.length }} {{ $t('form.placeholder.selectedRiskLevels') }}
                        </span>
                        <span v-else>
                            {{ $t(`risk.${values[0]}Risk`) |capitalize }}
                        </span>
                    </span>
                </template>
            </multiselect>
        </div>

        <!-- ******** DATE ******** -->
        <div :class="inputClasses">
            <b-form-datepicker id="filters-datepicker"
                               @input="updateFormStatus()"
                               :class="fieldStatus('date')"
                               :min="minDate"
                               :max="new Date()"
                               :date-format-options="{day: 'numeric', month: 'long', year: 'numeric'}"
                               placeholder="Date"
                               :locale="$i18n.locale"
                               v-model="newFiltersData.date">
            </b-form-datepicker>
        </div>

        <!-- ******** USER REPORTS ******** -->
        <div :class="inputClasses" v-if="isAuthorizedForUserReports">
            <div class="multiselect add-cursor" :class="showUserReportsStatus" @click="handleUserReports">
                <div class="multiselect__tags">
                <span class="multiselect__placeholder">
                    <IconBase view-box="0 0 16 12" width="15px" height="15px" class="mr-1"
                              v-if="showUserReportsStatus === 'applied'">
                        <IconCheck/>
                    </IconBase>
                    {{ $t('form.field.userReport') |capitalize }}
                </span>
                </div>
            </div>
        </div>

        <p v-if="isEmpty" class="none">{{ $t('error.noSensor') | capitalize }}</p>
        <div :class="inputClasses">
            <b-button type="submit"
                      class="btn-live-pollen"
                      v-if="!isFiltered || isApplyingFilters || isFiltersEmpty"
                      :disabled="isFiltersEmpty">
                {{ $t('common.button.applyFilters') | capitalize }}
            </b-button>
            <b-button @click="removeFilters()"
                      class="btn-live-pollen secondary"
                      v-else-if="!areOtherFiltersNull()">
                {{ $t('common.button.removeFilters') | capitalize }}
            </b-button>
        </div>
    </b-form>
</template>

<script>
import Vuex from "vuex";
import Multiselect from 'vue-multiselect';
import IconBase from "@/components/common/icons/IconBase";
import IconCheck from "@/components/common/icons/IconCheck";
import SecureLS from "@/plugins/secureLS";

export default {
    name: "DashboardFilters",
    components: {
        Multiselect,
        IconBase,
        IconCheck
    },
    props: {
        isEmpty: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            newFiltersData: null,
            riskLevelOptions: [0, 1, 2, 3],
            visibilityOptions: ['public', 'private'],
            isApplyingFilters: false,
            isFiltersEmpty: true,
            isFiltered: false,
            resetting: false
        }
    },
    methods: {
        ...Vuex.mapActions('filters', {
            setFilters: 'setFilters',
            resetFilters: 'resetFilters',
        }),
        ...Vuex.mapActions('account', {
            setAccounts: 'setAccounts'
        }),
        ...Vuex.mapActions('sensor', {
            setSensorsByAccount: 'setSensorsByAccount'
        }),
        ...Vuex.mapActions('reportType', {
            setReportTypes: 'setReportTypes'
        }),
        sensorName(sensor) {
            let sensorName = ''
            if (sensor.sensorName) {
                sensorName = sensor.sensorName;
            } else if (sensor.locality) {
                sensorName = sensor.locality
            }
            return ' ' + sensorName
        },
        handleUserReports() {
            if (this.newFiltersData.sensors.length === 0 &&
                (this.newFiltersData.pollens.length === 0 || this.isEnable)) {
                if (!this.newFiltersData.showUserReports) {
                    this.newFiltersData.showUserReports = true;
                } else {
                    this.newFiltersData.showUserReports = null;
                }
                this.updateFormStatus();
            } else if (this.newFiltersData.showUserReports) {
                this.newFiltersData.showUserReports = null;
                this.updateFormStatus();
            }
            if (!this.newFiltersData.showUserReports && this.areOtherFiltersNull()) {
                this.isApplyingFilters = false;
                this.isFiltered = false;
                this.resetting = true;
            }
        },
        handleFilters() {
            this.isApplyingFilters = false;
            this.isFiltered = true;
            if (this.newFiltersData.sensors.length > 0 && this.newFiltersData.fromMap) {
                this.newFiltersData.fromMap = false;
            }
            this.setFilters(this.newFiltersData);
            this.hideFilters();
        },
        removeFilters() {
            this.resetFilters().then(() => {
                this.newFiltersData = {...this.filters};
            })
            this.isApplyingFilters = false;
            this.isFiltered = false;
            this.resetting = true;
        },
        updateFormStatus() {
            this.isApplyingFilters = true;
            this.isFiltersEmpty = false;
        },
        fieldStatus(field) {
            let res = '';
            if (Array.isArray(this.newFiltersData[field])) {
                if (this.newFiltersData[field].length > 0) {
                    const isSame = this.areSimilarArrays(this.filters[field], this.newFiltersData[field]);
                    if (isSame) {
                        return 'applied';
                    } else {
                        return 'chosen';
                    }
                }
            } else {
                if (this.newFiltersData[field]) {
                    if (this.filters[field] === this.newFiltersData[field]) {
                        return 'applied';
                    } else {
                        return 'chosen';
                    }
                }
            }
            return res;
        },
        areOtherFiltersNull(excludedField = []) {
            let res = true;
            Object.entries(this.filters).forEach(([field, value]) => {
                if (!excludedField.includes(field)) {
                    if (Array.isArray(value)) {
                        if (value.length > 0) {
                            res = false
                        }
                    } else if (typeof value === 'object' && value) {
                        res = false;
                    } else if (typeof value === 'boolean' && value) {
                        res = false;
                    } else if (value) {
                        res = false;
                    }
                }
            })
            return res;
        },
        hideFilters() {
            this.$emit('triggerFilters', [])
        },
        areSimilarArrays(array, comparativeArray) {
            if (array.length > 0) {
                return (array.length === comparativeArray.length)
                    && array.every((element, index) => {
                        return element === comparativeArray[index];
                    });
            } else {
                return array.length === comparativeArray.length;
            }
        }
    },
    computed: {
        ...Vuex.mapGetters('account', {
            currentAccount: 'currentAccount',
            accounts: 'accounts'
        }),
        ...Vuex.mapGetters('filters', {
            filters: 'filters',
        }),
        ...Vuex.mapGetters('reportType', {
            reportTypes: 'reportTypes',
        }),
        ...Vuex.mapGetters('sensor', {
            sensorList: 'sensorList',
            getInstallationDate: 'getInstallationDate',
            accountsSensors: 'accountsSensors',
        }),
        orderedSensors() {
            let sensors = this.sensorList;
            if (this.filters.accounts.length > 0) {
                sensors = this.accountsSensors;
            }
            let prodSensors = sensors.filter((sensor) => sensor.status === 'prod')
            let renamedSensors = prodSensors.map((sensor) => {
                sensor.sensorName = this.sensorName(sensor);
                return sensor;
            })
            return renamedSensors.sort((a, b) => {
                const nameA = this.sensorName(a)
                const nameB = this.sensorName(b)
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
            });
        },
        orderedPollens() {
            let pollens = this.reportTypes;
            pollens.forEach((pollen) => {
                pollen.translatedName = this.$t(`pollen.type.${pollen.name}`).toLowerCase();
            })
            return pollens.sort(function (a, b) {
                if (a.translatedName < b.translatedName) return -1;
                if (a.translatedName > b.translatedName) return 1;
                return 0;
            });
        },
        orderedAccounts() {
            let accounts = this.accounts;
            return accounts.sort(function (a, b) {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            });
        },
        showUserReportsStatus() {
            if (this.newFiltersData.sensors.length > 0 || (this.newFiltersData.pollens.length > 0 && !this.isEnable)) {
                return 'disable';
            }
            if (this.newFiltersData.showUserReports) {
                if (this.filters.showUserReports === this.newFiltersData.showUserReports) {
                    return 'applied';
                } else {
                    return 'chosen';
                }
            }
            return '';
        },
        minDate() {
            return new Date(this.getInstallationDate);
        },
        isEnable() {
            let currentUser = SecureLS.get('currentUser');
            return currentUser.roles.includes('ROLE_ADMIN') || currentUser.roles.includes('ROLE_SUPER_ADMIN')
        },
        isAuthorizedForUserReports() {
            return !!(this.isEnable || this.currentAccount.planLevel !== "pro");
        },
        inputClasses() {
            if (this.isEnable) {
                return "mb-2 col-md-4 col-lg-3 col-12";
            } else {
                return "pl-0 mr-0 mb-2 col-md-auto col-12";
            }
        }
    },
    created() {
        this.newFiltersData = {...this.filters};
        this.setReportTypes();
        if (this.isEnable) this.setAccounts({paginated: false});
        this.isFiltered = false;
        this.isFiltersEmpty = true;
    },
    watch: {
        newFiltersData: {
            handler(value, oldValue) {
                if (value && oldValue) {
                    if (value.sensors.length > 0 ||
                        (value.sensors && Object.keys(value.sensors).length > 0)) {
                        if (!Array.isArray(value.sensors)) {
                            this.newFiltersData.sensors = [this.newFiltersData.sensors];
                        }
                        this.newFiltersData.showUserReports = false;
                    } else if (value.pollens.length > 0 && !this.isEnable) {
                        this.newFiltersData.showUserReports = false;
                    }
                }
                if (this.resetting) {
                    this.isFiltersEmpty = true;
                    this.resetting = false;
                }
            },
            deep: true
        },
        'filters.sensors'(value, oldValue) {
            if (!this.areSimilarArrays(value, oldValue)) {
                this.newFiltersData.sensors = this.filters.sensors;
                if (value.length === 1) {
                    this.isFiltersEmpty = false;
                    this.isFiltered = true;
                } else if (value.length === 0 && oldValue.length === 1 && this.areOtherFiltersNull('sensors')) {
                    this.isFiltersEmpty = true;
                    this.isFiltered = false;
                }
            }
        },
        'filters.pollens'(value, oldValue) {
            if (!this.areSimilarArrays(value, oldValue)) {
                this.newFiltersData.pollens = this.filters.pollens;
                this.isFiltersEmpty = false;
                this.isFiltered = true;
            }
        },
        'filters.date'(value, oldValue) {
            if (value !== oldValue) {
                this.newFiltersData.date = this.filters.date;
                this.isFiltersEmpty = false;
                this.isFiltered = true;
            }
        },
        'filters.accounts'(value, oldValue) {
            if (!this.areSimilarArrays(value, oldValue)) {
                let data = value.map(item => item.id);
                this.setSensorsByAccount({params: {accounts: data}});
            }
        },
        'filters.fromMap'(value, oldValue) {
            if (value !== oldValue) {
                this.newFiltersData.fromMap = value;
            }
        }
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.filter-control {
    z-index: 666;
    padding: 20px 10px 5px 10px;
    background-color: $white;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);

    .btn-live-pollen {
        width: 100%;
        height: 40px;
    }

    .close-control {
        width: 30px;
        height: 30px;
        cursor: pointer;
    }

    .none {
        margin-top: 15px;
        margin-bottom: 0;
        text-align: center;
    }

    .filter-select {
        border: none;
        border-radius: 0;
        background-color: transparent;
        border-bottom: 2px solid $light-grey;
        margin: 0 10px;
        font-size: 14px;
    }

    .b-form-btn-label-control {
        border: 1px solid $lightslategrey;
        border-radius: 4px;
        font-size: 14px;

        #filters-datepicker {
            min-height: 40px;
        }

        #filters-datepicker__value_ {
            padding-top: 12px;
            width: 100%;
        }

        output {
            width: 100%;
        }

        .b-calendar-grid {
            width: 100%;
        }

        &.chosen {
            border: 1px solid $orange-lifyair;

            #filters-datepicker__value_, .filters-datepicker {
                color: $orange-lifyair;
            }

            .btn {
                color: $orange-lifyair;
            }
        }

        &.applied {
            border: 1px solid $orange-lifyair;
            background-color: #FFEFE5;

            #filters-datepicker__value_, .filters-datepicker {
                color: $orange-lifyair;
            }

            .btn {
                color: $orange-lifyair;
            }
        }
    }

    .multiselect {
        border: 0;
        border-radius: 4px;

        .multiselect__select::before {
            top: 60%;
        }

        .multiselect__content-wrapper, .multiselect__tags {
            border-color: $lightslategrey;
        }

        .multiselect__placeholder {
            padding: 0 7px;
            color: $dimgrey;
            font-size: 14px;
            margin: 0;
        }

        .multiselect__single {
            color: $orange-lifyair;
        }

        .multiselect__option {
            background-color: $white-whitesmoke;
            color: $darkslategrey;
        }

        .multiselect__option--group {
            background-color: $white;
            color: $darkslategrey;
            font-size: 14px;
        }

        .multiselect__tag-icon {
            background-color: $orange-lifyair;

            &::after {
                color: $white;
            }
        }

        .multiselect__tag {
            display: none;
            color: $white;
            background-color: $orange-lifyair;

            &.sensor {
                overflow-wrap: break-word;
                padding-right: 10px;
            }
        }


        .multiselect__tags {
            padding: 10px 40px 0 8px;
        }

        &.chosen {
            .multiselect__tags {
                border: 1px solid $orange-lifyair;
            }

            .multiselect__select::before {
                color: $orange-lifyair;
                border-color: $orange-lifyair transparent transparent;
            }

            .multiselect__placeholder {
                color: $orange-lifyair;
            }
        }

        &.applied {

            .multiselect__tags {
                border: 1px solid $orange-lifyair;
            }

            .multiselect__select::before {
                color: $orange-lifyair;
                border-color: $orange-lifyair transparent transparent;
            }

            .multiselect__placeholder {
                color: $orange-lifyair;
            }

            .multiselect__tags {
                background-color: #FFEFE5;
            }

            .multiselect__single {
                background-color: #FFEFE5;
                margin: 0;
            }
        }

        &.disable {

            .multiselect__tags {
                border: 1px solid $lightgrey;
            }

            .multiselect__select::before {
                color: $lightgrey;
                border-color: $lightgrey transparent transparent;
            }

            .multiselect__placeholder {
                color: $lightgrey;
            }

            .multiselect__tags {
                background-color: $white-whitesmoke;
            }

            .multiselect__single {
                background-color: $white-whitesmoke;
                margin: 0;
            }
        }

        .multiselect__option {
            background-color: $white;
        }

        .multiselect__option--highlight {
            background-color: $white-whitesmoke;
            color: $dimgrey;
        }

        .multiselect__option--selected {
            color: $white;
            background-color: $orange-lifyair;
        }

        &.multiselect--disabled {
            //border: 1px solid $lightgrey;

            .multiselect__select {
                background: none;
            }

            .multiselect__select::before {
                color: $lightgrey;
                //border-color: $lightgrey transparent transparent;
            }

            .multiselect__placeholder {
                color: $lightgrey;
            }

            .multiselect__tags {
                background-color: $white-whitesmoke;
                border-color: $lightgrey;
            }

            .multiselect__single {
                background-color: $white-whitesmoke;
                margin: 0;
            }
        }
    }
}
</style>
