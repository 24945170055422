"use strict";
import axios from 'axios'
import Toast from "@/services/swal2/mixins";
import store from "@/store";
import i18n from "@/i18n";
import moment from "moment";

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL + '/v1',
})

const error401Messages = {
    "Invalid credentials.": i18n.t('common.toast.request.badCredentials'),
    "Authentication Required": i18n.t('common.toast.request.401'),
    "Expired JWT Token": i18n.t('common.toast.request.JWTExpired'),
    "Not authorized!": i18n.t('common.toast.request.accessDenied'),
}

const errorInterceptor = error => {
    // check if it's a server error
    if (!error.response) {
        return Promise.reject(error);
    }

    // all the error responses
    switch (error.response.status) {
        case 401: // authentication error, logout the user
            store.dispatch('login/logout')
            Toast.fire({
                icon: 'error',
                title: error401Messages[error.response.data.message],
            })
            break;

        case 500:
            Toast.fire({
                icon: 'error',
                title: i18n.t('common.toast.request.500'),
            })
            break;

        case 404:
            break;

        default:
            Toast.fire({
                icon: 'error',
                title: i18n.t('common.toast.request.basicError'),
            })
    }
    return Promise.reject(error);
}

// Interceptor for responses
const responseInterceptor = response => {
    switch (response.status) {
        case 200:
            // yay!
            break;
        case 204:
            response.data = {
                message: 'No data found',
                success: true,
                status: 204,
            }
            break;
        // any other cases
        default:
            Toast.fire({
                icon: 'success',
                title: i18n.t('common.toast.request.success'),
            })
    }

    return response;
}

const requestInterceptor = request => {
    const apiKey = store.getters["apiKey/getApiKey"]
    const now = moment().format('YYYYMMDD')
    if(apiKey){
        request.headers['x-api-key'] = apiKey;
        delete request.headers['Authorization'];
    } else {
        delete request.headers['x-api-key'];
    }
    request.headers['x-api-host'] = btoa(process.env.VUE_APP_PROJECT_ID + '_' + process.env.VUE_APP_PROJECT_ENV + '.' + now);
    return request;
}

service.interceptors.request.use(requestInterceptor);
service.interceptors.response.use(responseInterceptor, errorInterceptor);

export default service
