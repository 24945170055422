import axios from "../../plugins/axios";
import authHeader from "./authHeader";

export default {
    getSensorsConnexionCount
}

function getSensorsConnexionCount(params) {
    return axios.get('/map/sensor-analytics', {...authHeader.classicalHeader(), ...params})
}


