<template>
    <div class="row justify-content-center bg-white m-0">
        <div v-if="areUserReportsHistoryLoading">
            <div class="text-center m-5">
                <b-spinner variant="primary" label="loader" type="grow"></b-spinner>
            </div>
        </div>
        <UserReportsEvolutionBarChart v-if="!areUserReportsHistoryLoading" :user-reports="userReportsHistory"/>
    </div>
</template>

<script>
import UserReportsEvolutionBarChart from "@/components/dashboard/userReport/UserReportsEvolutionBarChart";
import Vuex from "vuex";

export default {
    name: "UserReportsEvolution",
    components: {
        UserReportsEvolutionBarChart
    },
    data() {
        return {}
    },
    computed: {
        ...Vuex.mapGetters('userReport', {
            userReportsHistory: 'userReportsHistory',
            areUserReportsHistoryLoading: 'areUserReportsHistoryLoading',
        }),
    }
}
</script>

<style lang="scss" scoped>
</style>
