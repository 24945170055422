<template>
    <div id="app">
        <component :is="layout">
            <router-view id="page-wrap"/>
        </component>
    </div>
</template>

<script>
import Dashboard from '@/views/Dashboard.vue'
import NoSidebar from "./components/layout/NoSideBar";
import Default from "./components/layout/Default";
import Vuex from "vuex";
import SecureLS from "@/plugins/secureLS";


const default_layout = "Default";

export default {
    name: 'App',
    components: {
        Dashboard,
        NoSidebar,
        Default
    },
    methods: {
        ...Vuex.mapActions('login', {
            logout: 'logout'
        }),
    },
    computed: {
        ...Vuex.mapGetters('account', {
            currentAccount: 'currentAccount'
        }),
        layout() {
            return (this.$route.meta.layout || default_layout);
        },
    },
    async created() {
        if (!localStorage.getItem('acceptedCgu')) {
            SecureLS.removeAll()
        }
        if (Object.keys(this.currentAccount).length !== 0) {
            this.$gtag.event('account', {
                'event_category': 'Account',
                'event_label': 'Account',
                'value': this.currentAccount.name
            })
        }
        let currentUser = SecureLS.get('currentUser');
        let tokenDate = SecureLS.get(btoa('token'));
        if (currentUser &&
            currentUser.roles.includes('ROLE_ACCOUNT_MANAGER') &&
            tokenDate.timestamp <= "2023-08-19T02:41:53.000Z") {
            await this.logout();
        }
    }
}
</script>

<style>

</style>
