import api from '@/services/api/index.js'

const state = {
    currentAccount: {},
    accounts: [],
    loadingStatus: false,
    request: {
        data: {},
        error: {}
    }
};


const mutations = {
    SET_CURRENT_ACCOUNT: (state, account) => {
        state.loadingStatus = true
        state.currentAccount = account;
        state.loadingStatus = false
    },
    SET_ACCOUNTS: (state, accounts) => {
        state.loadingStatus = true
        state.accounts = accounts;
        state.loadingStatus = false
    },
    BAD_REQUEST: (state, requestStatus) => {
        state.request.error = {status: requestStatus[1], message: requestStatus[0]}
    },
};
const actions = {
    setCurrentAccount: async (store) => {
        await api.account.getCurrentAccount()
            .then(response => {
                store.commit('SET_CURRENT_ACCOUNT', response.data.data)
            })
            .catch(error => {
                const requestStatus = [error.message, error.error_code];
                store.commit('BAD_REQUEST', requestStatus);
            });
    },
    setAccounts: async (store,data) => {
        await api.account.getAccounts(data)
            .then( response =>  {
                store.commit('SET_ACCOUNTS', response.data.data.accounts);
            })
            .catch(error => {
                const requestStatus = [error.message, error.error_code];
                store.commit('BAD_REQUEST', requestStatus);
            });
    },
};

const getters = {
    currentAccount: state => {
        return state.currentAccount
    },
    accounts: state => {
        return state.accounts
    },
    loadingStatus: state => {
        return state.loadingStatus
    },
    getRequest: state => {
        return state.request
    },
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
