import api from '@/services/api/index.js'

const state = {
    globalConcentrations: [],
    isLoading: false,
    request: {
        data: {},
        error: {}
    }
};

const mutations = {
    SET_GLOBAL_CONCENTRATIONS: (state, globalConcentrations) => {
        state.globalConcentrations = globalConcentrations;
    },
    SET_LOADING: (state) => {
        state.isLoading = !state.isLoading;
    },
    BAD_REQUEST: (state, requestStatus) => {
        state.request.error = {status: requestStatus[1], message: requestStatus[0]}
    }
};

const actions = {
    setGlobalConcentrations: async (store, params) => {
        store.commit('SET_LOADING');
        await api.globalConcentration.getGlobalConcentrations(params)
            .then(response => {
                store.commit('SET_GLOBAL_CONCENTRATIONS', response.data.data.globalConcentrations);
                store.commit('SET_LOADING');
            })
            .catch(error => {
                const requestStatus = [error.message, error.error_code];
                store.commit('BAD_REQUEST', requestStatus);
                store.commit('SET_LOADING');
            });
    }
};

const getters = {
    globalConcentrations: state => {
        return state.globalConcentrations;
    },
    isLoading: state => {
        return state.isLoading;
    }
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
