import axios from "../../plugins/axios";
import authHeader from "./authHeader";

export default {
    getGlobalConcentrations
}

function getGlobalConcentrations(data) {
    return axios.get('/map/global-concentrations', {...authHeader.classicalHeader(), ...data})
}


