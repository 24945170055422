<template>
    <dashboard-map style="padding: 56px 0 0 0"></dashboard-map>
</template>

<script>
import DashboardMap from "../components/dashboard/DashboardMap";

export default {
    components: {
        DashboardMap,
    },
    data() {
        return {}
    },
}
</script>

<style lang="scss">
.full-map-size {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
</style>
