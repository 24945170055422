import i18n from '@/i18n'
import Vue from 'vue'
import pollenService from "@/services/pollen/pollenService";
import moment from "moment";

export const chartTranslation = [
    {
        "name": "en",
        "options": {
            "months": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            "shortMonths": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            "days": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
            "shortDays": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            "toolbar": {
                "exportToSVG": "Download SVG",
                "exportToPNG": "Download PNG",
                "menu": "Menu",
                "selection": "Selection",
                "selectionZoom": "Selection Zoom",
                "zoomIn": "Zoom In",
                "zoomOut": "Zoom Out",
                "pan": "Panning",
                "reset": "Reset Zoom"
            }
        }
    },
    {
        "name": "fr",
        "options": {
            "months": ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
            "shortMonths": ["Janv", "Févr", "Mars", "Avr", "Mai", "Juin", "Juill", "Août", "Sept", "Oct", "Nov", "Déc"],
            "days": ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
            "shortDays": ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
            "toolbar": {
                "exportToSVG": "Télécharger SVG",
                "exportToPNG": "Télécharger PNG",
                "menu": "Menu",
                "selection": "Selection",
                "selectionZoom": "Selection Zoom",
                "zoomIn": "Zoomer",
                "zoomOut": "Dézoomer",
                "pan": "Panoramique",
                "reset": "Réinitialiser le zoom"
            }
        }
    }
]

export const apexchartsOptions = {
    riskEvolutionBar: {
        chart: {
            type: 'bar',
            locales: chartTranslation,
            defaultLocale: "en",
            toolbar: {
                show: false
            },
            animations: {
                speed: 800,
                animateGradually: {
                    enabled: true,
                    delay: 1500
                },
            },
            zoom: {
                type: 'x',
                enabled: false,
                autoScaleYaxis: true
            },
        },
        noData: {
            text: 'Loading...'
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '80%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            type: 'datetime',
            labels: {
                format: 'dd/MM',
                rotate: -45,
                rotateAlways: true,
                minHeight: 50,
                maxHeight: 180,
                hideOverlappingLabels: false,
                showDuplicates: false,
                trim: false,
                style: {
                    fontFamily: 'Myriad Pro Regular, Arial, sans-serif'
                },
            }
        },
        yaxis: {
            labels: {
                formatter: function (value) {
                    return Vue.options.filters.capitalize(i18n.t('risk.' + value));
                },
                style: {
                    fontFamily: 'Myriad Pro Regular, Arial, sans-serif'
                },
            },
            tickPlacement: 'on',
            forceNiceScale: true,
            max: 3
        },
        fill: {
            opacity: 1
        },
        colors: [
            function ({value}) {
                if (value === 0.2) return "#F5F5F5"
                return pollenService.getHexColorByValue(Math.floor(value));
            }
        ],
        tooltip: {
            enabled: false,
            x: {
                show: true,
                format: 'dd MMMM',
            }
        },
        grid: {
            show: false,
            padding: {
                top: 3,
                right: 10,
                bottom: 3,
                left: 10
            },
        },
    },
    userReportsEvolutionBar: {
        chart: {
            type: 'bar',
            locales: chartTranslation,
            defaultLocale: "en",
            toolbar: {
                show: false
            },
            animations: {
                speed: 800,
                animateGradually: {
                    enabled: true,
                    delay: 1500
                },
            },
            zoom: {
                type: 'x',
                enabled: false,
                autoScaleYaxis: true
            },
        },
        noData: {
            text: 'Loading...'
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '80%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            type: 'datetime',
            labels: {
                format: 'dd/MM',
                rotate: -45,
                rotateAlways: true,
                minHeight: 50,
                maxHeight: 180,
                hideOverlappingLabels: false,
                showDuplicates: false,
                trim: false,
                style: {
                    fontFamily: 'Myriad Pro Regular, Arial, sans-serif'
                },
            }
        },
        yaxis: {
            labels: {
                formatter: function (value) {
                    return Math.floor(value);
                },
                style: {
                    fontFamily: 'Myriad Pro Regular, Arial, sans-serif'
                },
            },
            tickPlacement: 'on',
            forceNiceScale: true
        },
        grid: {
            show: false,
            padding: {
                top: 3,
                right: 10,
                bottom: 3,
                left: 10
            },
        },
        fill: {
            opacity: 1
        },
        colors: ['#FF5A00'],
        tooltip: {
            enabled: true,
            x: {
                show: false,
            },
            y: {
                formatter: undefined,
                title: {
                    formatter:
                        function (value) {
                            return value;
                        },
                },
            },
        }
    },
    globalConcentrationLineChart: {
        chart: {
            type: 'line',
            locales: chartTranslation,
            defaultLocale: "en",
            toolbar: {
                show: false
            },
            zoom: {
                type: 'x',
                enabled: false,
                autoScaleYaxis: true
            },
        },
        colors:['#FF5A00'],
        noData: {
            text: 'Loading...'
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            type: 'datetime',
            labels: {
                format: 'HH:00',
                rotate: -45,
                minHeight: 50,
                maxHeight: 180,
                rotateAlways: true,
                datetimeUTC: false,
                hideOverlappingLabels: true,
                showDuplicates: false,
                trim: true,
                style: {
                    fontFamily: 'Myriad Pro Regular, Arial, sans-serif',
                    fontWeight: 400,
                },
            },
            tooltip: {
                enabled: false
            },
            axisBorder: {
                show: true,
                color: '#C9C9C9',
                height: 3,
                width: '100%',
                offsetX: 0,
                offsetY: 0
            },
        },
        yaxis: {
            labels: {
                show: false
            },
            axisBorder: {
                show: true,
                color: '#C9C9C9',
                offsetX: -5,
                offsetY: 0,
                width: 3
            },
        },
        axisBorder: {
            show: true,
            color: '#C9C9C9'
        },
        stroke: {
            curve: 'smooth',
            width: 4
        },
        grid: {
            show: false,
            padding: {
                top: 3,
                right: 10,
                bottom: 3,
                left: 10
            },
        },
        tooltip: {
            enabled: true,
            x: {
                show: false,
            },
            y: {
                formatter: function(value, opts) {
                    const xValue = opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].x;
                    return moment(xValue).format('HH:mm')
                },
                title: {
                    formatter: function () {
                        return '';
                    }
                },
            },
        }
    },
};
