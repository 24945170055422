<template>
    <div class="row justify-content-center bg-white m-0" id="popover-evolution">
        <div v-if="isLoading">
            <div class="text-center m-5">
                <b-spinner variant="primary" label="loader" type="grow"></b-spinner>
            </div>
        </div>
        <b-col cols="12" class="mt-2" v-if="!isDataComplete && !isLoading">
            <div class="basic-card bg-pink-mistyrose clr-indianred">
                <b-row align-v="center" align-h="around">
                    <b-col cols="2" class="m-0 pr-1 text-center">
                        <IconBase :style="{maxHeight: '40px'}" viewBox="0 0 21 20" width="100%" height="100%">
                            <IconClock/>
                        </IconBase>
                    </b-col>
                    <b-col cols="10" class="m-0 pl-0 caption clr-indianred">
                        {{ $t('emptyState.riskEvolutionNotComplete') }}
                    </b-col>
                </b-row>
            </div>
        </b-col>
        <GlobalConcentrationChart
            @updateIsComplete="updateIsComplete"
            v-if="!isLoading"
            :global-concentrations="globalConcentrations"/>
    </div>
</template>

<script>
import GlobalConcentrationChart from "@/components/dashboard/globalConcentration/GlobalConcentrationChart";
import Vuex from "vuex";
import IconBase from "@/components/common/icons/IconBase";
import IconClock from "@/components/common/icons/IconClock";

export default {
    name: "DailyGlobalConcentration",
    components: {
        IconClock,
        IconBase,
        GlobalConcentrationChart
    },
    props: {
        filters: {
            type: Object,
            default() {
                return {
                    account: null,
                    accountGroup: null,
                    type: 'pollen',
                    pollenCategories: [],
                    status: 'prod'
                }
            }
        }
    },
    data() {
        return {
            isDataComplete: true
        }
    },
    methods: {
        updateIsComplete(val) {
            this.isDataComplete = val;
        }
    },
    computed: {
        ...Vuex.mapGetters('globalConcentration', {
            globalConcentrations: 'globalConcentrations',
            isLoading: 'isLoading'
        })
    }
}
</script>

<style lang="scss" scoped>
</style>
