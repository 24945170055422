import Vue from "vue";
import i18n from "@/i18n";

export default {
    riskValueByMaxConcentration,
    riskValueByRiskLevel,
    riskValueByPollenWithGivenSensor,
    riskValueByRiskLevelRGB,
    riskValueByRiskLevelString,
    riskValueByColor,
    getRiskList
}

function riskValueByMaxConcentration(sensor){
    let colors = ['#d4d4d4','#f6c90f','#ff9c5b','#d9534f']
    let value = 0
    let risks = sensor.dataReference.riskZone.risks;
    let reports = sensor.dataReference.reports;

    for (let i = 0; i < reports.length; i++){
        let foundRisk = risks.find(element => element.reportType.id === reports[i].reportType.id);
        let newValue = 0;

        if (value === 3) { break; }

        if (reports[i].value >= foundRisk.level4){
            newValue  = 3
        } else if (reports[i].value >= foundRisk.level2){
            newValue  = 2
        } else if (reports[i].value >= foundRisk.level1){
            newValue  = 1
        }

        value < newValue ? value = newValue : '';
    }
    return colors[value]
}

// eslint-disable-next-line no-unused-vars
function riskValueByPollenWithGivenSensor(data =null,riskZone=null){
    let colors = ['#d4d4d4','#f6c90f','#ff9c5b','#d9534f']
    // let value = 0
    // let risk = riskZone;
    // let concentration = data;
    //
    // if (concentration >= risk.level4){
    //     value  = 3
    // } else if (concentration >= risk.level2){
    //     value  = 2
    // } else if (concentration >= risk.level1){
    //     value  = 1
    // }

    // return colors[value]
    return colors[0]
}

function riskValueByRiskLevel(risk){
    let colors = {
        0:'#d4d4d4',
        1:'#4795b3',
        2:'#4ba68d',
        3:'#f6c90f',
        4:'#ff9c5b',
        5:'#d9534f',
        null:'#919496',
    }
    return colors[risk]
}

function riskValueByRiskLevelString(risk){
    let colors = {
        0:'lightslategrey',
        1:'steelblue',
        2:'cadetblue',
        3:'gold',
        4:'orange-sandybrown',
        5:'indianred',
        null:'lightslategrey',
    }
    return colors[risk]
}

function riskValueByRiskLevelRGB(risk){
    let colors = {
        0:{r: 212, g: 212, b: 212},
        1:{r: 71, g: 149, b: 179},
        2:{r: 75, g: 166, b: 141},
        3:{r: 221, g: 201, b: 15},
        4:{r: 255, g: 156, b: 91},
        5:{r: 217, g: 83, b: 79},
        null:{r: 212, g: 212, b: 212},
    }
    return colors[risk]
}

function riskValueByColor(color) {
    let values = {
        'lightgrey': 'off',
        'cadetblue': 0,
        'gold': 1,
        'orange-sandybrown': 2,
        'indianred': 3,
        null: 0
    }

    return values[color]
}

function getRiskList() {
    return [
        {
            value: 0,
            color: 'lightslategrey',
            name: Vue.options.filters.capitalize(i18n.t('risk.inactive'))
        },
        {
            value: 1,
            color: 'cadetblue',
            name: Vue.options.filters.capitalize(i18n.t('risk.none'))
        },
        {
            value: 2,
            color: 'gold',
            name: Vue.options.filters.capitalize(i18n.t('risk.low'))
        },
        {
            value: 3,
            color: 'orange-sandybrown',
            name: Vue.options.filters.capitalize(i18n.t('risk.medium'))
        },
        {
            value: 4,
            color: 'indianred',
            name: Vue.options.filters.capitalize(i18n.t('risk.high'))
        }
    ]
}
