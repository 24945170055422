import axios from "../../plugins/axios";
import authHeader from "./authHeader";

export default {
    getSensors,
    getUserSensors,
    getActualRollingRisksBySensor,
    getRollingRiskEvolutionBySensor
}

function getSensors(){
    return axios.get('/sensors', authHeader.classicalHeader());
}

function getUserSensors(data) {
    return axios.get('/map/sensors', {...authHeader.classicalHeader(), ...data})
}

function getActualRollingRisksBySensor(sensor) {
    return axios.get('/sensors/' + sensor + '/rolling-risks/current', authHeader.classicalHeader())
}

function getRollingRiskEvolutionBySensor(sensor, params) {
    return axios.get(
        '/sensors/' + sensor + '/rolling-risks/history',
        {...authHeader.classicalHeader(), ...params}
    )
}
