import api from '@/services/api/index.js'
import weatherApi from "@/services/api/weatherApi";

const state = {
	reports: {},
	globalReports: {},
	weatherReports:{},
	loadingStatus: false,
	request: {
		data:{
			sensorId:null,
		},
		error: {}
	},
	weather:{}
};

const mutations = {
	SET_REPORTS: (state, reports) => {
		state.loadingStatus = true
		delete reports.global;
		state.reports = reports;
		state.loadingStatus = false
	},
	SET_GLOBAL_REPORTS: (state, reports) => {
		state.loadingStatus = true
		state.globalReports = reports;
		state.loadingStatus = false
	},
	SET_WEATHER_REPORTS: (state, reports) => {
		state.loadingStatus = true
		state.weatherReports = reports;
		state.loadingStatus = false
	},
	BAD_REQUEST: (state, requestStatus) => {
		state.request.error = {status: requestStatus[1], message: requestStatus[0]}
	},
	SET_CHOSEN_SENSOR: (state, sensorId) => {
		state.request.data.sensorId = sensorId;
	},
	SET_ACTUAL_WEATHER: (state, weather) => {
		state.weather = weather;
	},
	REPORTS_IS_LOADING_STATUS: (state) => {
		state.loadingStatus = !state.loadingStatus
	},
};

const actions = {
	setReports: async (store,data) => {
		await api.report.getReports(data)
			.then( response =>  {
				store.commit('SET_GLOBAL_REPORTS', response.data.data.reports.global);
				store.commit('SET_REPORTS', response.data.data.reports);
			})
			.catch(error => {
				const requestStatus = [error.message,error.error_code];
				store.commit('BAD_REQUEST', requestStatus);
			});
	},
	setWeatherReports: async (store,data) => {
		await api.report.getWeatherReports(data)
			.then( response =>  {
				store.commit('SET_WEATHER_REPORTS', response.data.data);
			})
			.catch(error => {
				const requestStatus = [error.message,error.error_code];
				store.commit('BAD_REQUEST', requestStatus);
			});
	},
	setActualWeather: async (store,data) => {
		await weatherApi.getActualWeather(data)
			.then( response =>  {
				store.commit('SET_ACTUAL_WEATHER', response.data);
			})
			.catch(error => {
				const requestStatus = [error.message,error.error_code];
				store.commit('BAD_REQUEST', requestStatus);
			});
	},
	setChosenSensor:(store,sensorId) => {
		store.commit('SET_CHOSEN_SENSOR',sensorId);
	},
	removeChosenSensor:(store) => {
		store.commit('SET_CHOSEN_SENSOR',null);
	},

};

const getters = {
	reports: state => {
		return state.reports
	},
	globalReports: state => {
		return state.globalReports
	},
	reportsBySensor: (state) => (id) => {
		return state.reports[id];
	},
	reportsBySensorAndCategory: (state) => (id, category) => {
		return state.reports[id].filter(item => item.category === category)
	},
	globalReportsByCategory: (state) => (category) => {
		return state.globalReports.filter(item => item.category === category)
	},
	weatherReports: state => {
		return state.weatherReports
	},
	actualWeather: state => {
		return state.weather
	},
	chosenSensor: state => {
		return state.request.data.sensorId
	},
	loadingStatus : state => {
		return state.loadingStatus
	},
	getRequest : state => {
		return state.request
	},
};

export default {
	namespaced: true,
	state: state,
	mutations: mutations,
	actions: actions,
	getters: getters
}